import { useQuery as c, useMutation as i } from "@tanstack/react-query";
import a from "axios";
const d = (t, e) => a.get(
  "/echo",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), p = (t) => ["/echo", ...t ? [t] : []], _ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? p(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function g(t, e) {
  const n = _(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const m = (t, e) => a.post(
  "/echo",
  t,
  e
), l = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return m(o, n);
  }, ...e };
}, q = (t) => {
  const e = l(t);
  return i(e);
}, jl = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getEcho: d,
  getGetEchoQueryKey: p,
  getGetEchoQueryOptions: _,
  getPostEchoMutationOptions: l,
  postEcho: m,
  useGetEcho: g,
  usePostEcho: q
}, Symbol.toStringTag, { value: "Module" })), Zl = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  ordercreated: "order.created",
  orderupdated: "order.updated",
  refundcreated: "refund.created",
  refundupdated: "refund.updated",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  spend_save_accountcreated: "spend_save_account.created",
  spend_save_accountclosed: "spend_save_account.closed",
  spend_save_offercreated: "spend_save_offer.created",
  spend_save_offerclosed: "spend_save_offer.closed"
}, Jl = {
  normal: "normal",
  sync: "sync"
}, tg = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, eg = {
  enabled: "enabled",
  disabled: "disabled"
}, ng = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, rg = {
  active: "active",
  inactive: "inactive"
}, sg = {
  read: "read",
  unread: "unread"
}, og = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, ag = {
  Error: "Error",
  Warning: "Warning"
}, ug = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, ig = {
  pending_underwriting: "pending_underwriting",
  underwriting: "underwriting",
  failed: "failed",
  success: "success"
}, cg = {
  failed_plaid_auth: "failed_plaid_auth"
}, yg = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, dg = {
  pending_kitchen_response: "pending_kitchen_response",
  pending_kitchen_underwriting: "pending_kitchen_underwriting",
  failed: "failed",
  success: "success"
}, pg = {
  failed_plaid_auth: "failed_plaid_auth",
  no_plaid_dispersement_observed: "no_plaid_dispersement_observed"
}, _g = {
  pst: "pst",
  est: "est",
  utc: "utc"
}, mg = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, lg = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, gg = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AS: "AS",
  GU: "GU",
  MP: "MP",
  PR: "PR",
  VI: "VI",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, qg = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, fg = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  term: "term"
}, Og = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, hg = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, Kg = {
  pending_response: "pending_response",
  completed: "completed"
}, Pg = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, bg = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table",
  orders_table: "orders_table"
}, xg = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, Mg = {
  shopify: "shopify",
  amazon: "amazon",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  magento: "magento",
  xero: "xero",
  net_suite: "net_suite",
  sage_cloud: "sage_cloud",
  sage_intacct: "sage_intacct",
  square: "square",
  zohobooks: "zohobooks",
  quickbooks: "quickbooks",
  freshbooks: "freshbooks"
}, kg = {
  payments: "payments",
  accounting: "accounting"
}, Fg = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, Rg = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, Bg = {
  offer_amount: "offer_amount",
  fee_amount: "fee_amount"
}, Cg = {
  regular: "regular",
  special: "special"
}, Eg = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, Sg = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, vg = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, Ag = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, Dg = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, Ug = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Ng = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Gg = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, Tg = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Ig = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, wg = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, Qg = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, Lg = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, Wg = {
  direct_debit: "direct_debit",
  net_settlement: "net_settlement"
}, Vg = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, $g = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, zg = {
  opened: "opened",
  failed: "failed",
  succeeded: "succeeded"
}, Hg = {
  payment_method_not_refundable: "payment_method_not_refundable",
  bank_return: "bank_return"
}, Yg = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, Xg = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, jg = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, Zg = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, Jg = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, tq = {
  admin: "admin",
  merchant_representative: "merchant_representative",
  developer: "developer",
  controller: "controller"
}, eq = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added",
  send_otp: "send_otp"
}, nq = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, rq = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, sq = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, oq = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, aq = {
  void_cash_advance: "void_cash_advance"
}, uq = {
  basic_offer_generation: "basic_offer_generation",
  underwriting: "underwriting",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  introductory_offer_preview: "introductory_offer_preview",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  introductory_offer_underwriting_rate_limit_exceeded: "introductory_offer_underwriting_rate_limit_exceeded",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification",
  application_temporary_waiting_screen: "application_temporary_waiting_screen"
}, iq = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  capital: "capital",
  merchant_bank: "merchant_bank"
}, cq = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  start_application: "start_application",
  offer_preview: "offer_preview",
  application_temporary_waiting_screen: "application_temporary_waiting_screen"
}, yq = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, dq = {
  active: "active",
  inactive: "inactive"
}, pq = {
  opted_in: "opted_in",
  opted_out: "opted_out",
  never_opted_in: "never_opted_in"
}, _q = {
  minimum: "minimum",
  sales_based: "sales_based"
}, mq = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, lq = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, gq = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment",
  card_dispute_credit: "card_dispute_credit"
}, qq = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, fq = {
  money_in: "money_in",
  money_out: "money_out"
}, Oq = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, hq = {
  manual: "manual",
  payout: "payout"
}, Kq = {
  electrical: "electrical",
  wire: "wire"
}, Pq = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, bq = {
  credit_limit: "credit_limit"
}, xq = {
  checking: "checking",
  savings: "savings"
}, Mq = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, kq = {
  physical: "physical",
  virtual: "virtual"
}, Fq = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Rq = {
  cash: "cash",
  credit: "credit"
}, Bq = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, Cq = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Eq = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, Sq = {
  checking: "checking",
  savings: "savings"
}, vq = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Aq = {
  pre_launch_email: "pre_launch_email",
  capital_product_offer_email: "capital_product_offer_email"
}, Dq = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, Uq = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Nq = {
  ucc: "ucc",
  federal: "federal",
  state: "state",
  judgment: "judgment"
}, Gq = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, Tq = {
  pending_user: "pending_user",
  user_reviewed: "user_reviewed",
  do_not_need_user_review: "do_not_need_user_review"
}, Iq = {
  date_of_birth: "date_of_birth",
  address: "address",
  name: "name",
  tin: "tin"
}, wq = {
  address: "address",
  name: "name",
  tin: "tin"
}, Qq = {
  incomplete: "incomplete",
  approved: "approved",
  denied: "denied",
  under_review: "under_review",
  pending: "pending"
}, Lq = {
  out_of_date_statements: "out_of_date_statements",
  disbursements_not_found: "disbursements_not_found"
}, Wq = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, Vq = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, $q = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, zq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, Hq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, Yq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, Xq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, jq = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, Zq = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, Jq = {
  manual: "manual",
  rutter: "rutter"
}, tf = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean",
  long: "long"
}, ef = {
  not_started: "not_started",
  under_review: "under_review",
  approved: "approved"
}, nf = {
  business: "business",
  account: "account"
}, rf = {
  system: "system",
  user: "user"
}, sf = {
  active: "active",
  inactive: "inactive"
}, of = {
  not_started: "not_started",
  completed: "completed",
  in_progress: "in_progress",
  failed: "failed"
}, af = {
  partner_datashare: "partner_datashare",
  manual_edit: "manual_edit",
  kyc: "kyc",
  auto_opt_in: "auto_opt_in",
  auto_opt_in_backfill: "auto_opt_in_backfill"
}, uf = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, cf = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record",
  intro_offer: "intro_offer"
}, yf = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, df = {
  partner_wide_program: "partner_wide_program",
  business_level_program: "business_level_program"
}, pf = {
  under_review: "under_review",
  scheduled: "scheduled",
  running: "running",
  ended: "ended",
  denied: "denied"
}, _f = {
  revenue_share: "revenue_share",
  parafin_funded: "parafin_funded"
}, mf = {
  apply: "apply",
  lift: "lift"
}, lf = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location"
}, gf = {
  pending: "pending",
  under_review: "under_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  error: "error"
}, qf = {
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday"
}, ff = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  stripe_ingestion: "stripe_ingestion",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, Of = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, hf = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Kf = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, Pf = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLE: "SLE",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  USN: "USN",
  UYI: "UYI",
  UYU: "UYU",
  UYW: "UYW",
  UZS: "UZS",
  VED: "VED",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XBA: "XBA",
  XBB: "XBB",
  XBC: "XBC",
  XBD: "XBD",
  XCD: "XCD",
  XDR: "XDR",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  XSU: "XSU",
  XTS: "XTS",
  XUA: "XUA",
  XXX: "XXX",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL"
}, bf = {
  USD: "USD",
  CAD: "CAD"
}, xf = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, Mf = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, kf = {
  offer_summary: "offer_summary"
}, Ff = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, Rf = {
  US: "US",
  CA: "CA"
}, Bf = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Cf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Ef = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, Sf = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, vf = {
  chargeback: "chargeback",
  normal: "normal"
}, Af = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Df = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Uf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Nf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Gf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Tf = {
  physical: "physical",
  virtual: "virtual"
}, If = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, wf = {
  physical: "physical",
  virtual: "virtual"
}, Qf = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Lf = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Wf = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Vf = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, $f = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, zf = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Hf = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, Yf = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Xf = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, jf = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Zf = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, Jf = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, tO = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, eO = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, nO = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl",
  merchant_bank: "merchant_bank"
}, rO = {
  new: "new",
  verified: "verified"
}, sO = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, oO = {
  withdrawn: "withdrawn",
  expired: "expired",
  canceled: "canceled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer"
}, aO = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  problematic_payment_history_with_others: "problematic_payment_history_with_others",
  collection_action_or_judgement: "collection_action_or_judgement",
  la_wildfire_jan2025: "la_wildfire_jan2025",
  temporary_program_termination: "temporary_program_termination",
  "": ""
}, uO = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, iO = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, f = (t, e) => a.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), O = (t) => ["/business_cores", ...t ? [t] : []], h = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? O(t), queryFn: ({ signal: u }) => f(t, { signal: u, ...r }), ...n };
};
function cO(t, e) {
  const n = h(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const K = (t, e) => a.post(
  "/business_cores",
  t,
  e
), P = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return K(o, n);
  }, ...e };
}, yO = (t) => {
  const e = P(t);
  return i(e);
}, b = (t, e) => a.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), x = (t) => ["/business_annotations", ...t ? [t] : []], M = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? x(t), queryFn: ({ signal: u }) => b(t, { signal: u, ...r }), ...n };
};
function dO(t, e) {
  const n = M(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const k = (t, e) => a.post(
  "/business_annotations",
  t,
  e
), F = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return k(o, n);
  }, ...e };
}, pO = (t) => {
  const e = F(t);
  return i(e);
}, R = (t, e) => a.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), B = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? B(t), queryFn: ({ signal: u }) => R(t, { signal: u, ...r }), ...n };
};
function _O(t, e) {
  const n = C(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const E = (t, e) => a.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), S = (t) => ["/cash_advance_pauses", ...t ? [t] : []], v = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? S(t), queryFn: ({ signal: u }) => E(t, { signal: u, ...r }), ...n };
};
function mO(t, e) {
  const n = v(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const A = (t, e) => a.post(
  "/cash_advance_pauses",
  t,
  e
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return A(o, n);
  }, ...e };
}, lO = (t) => {
  const e = D(t);
  return i(e);
}, U = (t, e) => a.delete(
  `/cash_advance_pauses/${t}`,
  e
), N = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o } = s ?? {};
    return U(o, n);
  }, ...e };
}, gO = (t) => {
  const e = N(t);
  return i(e);
}, G = (t, e, n) => a.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), T = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o, data: u } = s ?? {};
    return G(o, u, n);
  }, ...e };
}, qO = (t) => {
  const e = T(t);
  return i(e);
}, I = (t, e) => a.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), w = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], Q = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? w(t), queryFn: ({ signal: u }) => I(t, { signal: u, ...r }), ...n };
};
function fO(t, e) {
  const n = Q(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const L = (t, e) => a.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), W = (t) => ["/future_activities", ...t ? [t] : []], V = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? W(t), queryFn: ({ signal: u }) => L(t, { signal: u, ...r }), ...n };
};
function OO(t, e) {
  const n = V(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $ = (t, e) => a.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), z = (t) => ["/partners", ...t ? [t] : []], H = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? z(t), queryFn: ({ signal: u }) => $(t, { signal: u, ...r }), ...n };
};
function hO(t, e) {
  const n = H(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Y = (t, e) => a.post(
  "/partners",
  t,
  e
), X = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Y(o, n);
  }, ...e };
}, KO = (t) => {
  const e = X(t);
  return i(e);
}, j = (t) => a.get(
  "/partners/underwriting",
  t
), Z = () => ["/partners/underwriting"], J = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Z(), queryFn: ({ signal: o }) => j({ signal: o, ...n }), ...e };
};
function PO(t) {
  const e = J(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const tt = (t, e, n) => a.patch(
  `/partners/${t}`,
  e,
  n
), et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return tt(o, u, n);
  }, ...e };
}, bO = (t) => {
  const e = et(t);
  return i(e);
}, nt = (t, e) => a.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rt = (t) => ["/partner/api_keys", ...t ? [t] : []], st = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rt(t), queryFn: ({ signal: u }) => nt(t, { signal: u, ...r }), ...n };
};
function xO(t, e) {
  const n = st(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ot = (t, e) => a.post(
  "/partner/api_keys",
  t,
  e
), at = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ot(o, n);
  }, ...e };
}, MO = (t) => {
  const e = at(t);
  return i(e);
}, ut = (t) => a.get(
  "/partner/organizations",
  t
), it = () => ["/partner/organizations"], ct = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? it(), queryFn: ({ signal: o }) => ut({ signal: o, ...n }), ...e };
};
function kO(t) {
  const e = ct(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const yt = (t, e) => a.post(
  "/partner/organizations",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yt(o, n);
  }, ...e };
}, FO = (t) => {
  const e = dt(t);
  return i(e);
}, pt = (t, e) => a.post(
  "/partner/organizations/link_partner",
  t,
  e
), _t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pt(o, n);
  }, ...e };
}, RO = (t) => {
  const e = _t(t);
  return i(e);
}, mt = (t, e) => a.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lt = (t) => ["/partner/organizations/partners", ...t ? [t] : []], gt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lt(t), queryFn: ({ signal: u }) => mt(t, { signal: u, ...r }), ...n };
};
function BO(t, e) {
  const n = gt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qt = (t, e) => a.post(
  "/slack/authorize",
  t,
  e
), ft = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qt(o, n);
  }, ...e };
}, CO = (t) => {
  const e = ft(t);
  return i(e);
}, Ot = (t) => a.get(
  "/slack/channels",
  t
), ht = () => ["/slack/channels"], Kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ht(), queryFn: ({ signal: o }) => Ot({ signal: o, ...n }), ...e };
};
function EO(t) {
  const e = Kt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Pt = (t, e) => a.delete(
  `/slack/channels/${t}`,
  e
), bt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Pt(o, n);
  }, ...e };
}, SO = (t) => {
  const e = bt(t);
  return i(e);
}, xt = (t) => a.get(
  "/partner/users",
  t
), Mt = () => ["/partner/users"], kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Mt(), queryFn: ({ signal: o }) => xt({ signal: o, ...n }), ...e };
};
function vO(t) {
  const e = kt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ft = (t, e) => a.post(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ft(o, n);
  }, ...e };
}, AO = (t) => {
  const e = Rt(t);
  return i(e);
}, Bt = (t, e) => a.patch(
  "/partner/users",
  t,
  e
), Ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bt(o, n);
  }, ...e };
}, DO = (t) => {
  const e = Ct(t);
  return i(e);
}, Et = (t, e) => a.delete(
  "/partner/delete_users",
  { data: t, ...e }
), St = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Et(o, n);
  }, ...e };
}, UO = (t) => {
  const e = St(t);
  return i(e);
}, vt = (t, e) => a.delete(
  "/organization/delete_users",
  { data: t, ...e }
), At = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vt(o, n);
  }, ...e };
}, NO = (t) => {
  const e = At(t);
  return i(e);
}, Dt = (t, e) => a.post(
  "/partner/users/v2",
  t,
  e
), Ut = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Dt(o, n);
  }, ...e };
}, GO = (t) => {
  const e = Ut(t);
  return i(e);
}, Nt = (t, e) => a.patch(
  "/partner/users/v2",
  t,
  e
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Nt(o, n);
  }, ...e };
}, TO = (t) => {
  const e = Gt(t);
  return i(e);
}, Tt = (t, e) => a.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), It = (t) => ["/partner/public_configs", ...t ? [t] : []], wt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? It(t), queryFn: ({ signal: u }) => Tt(t, { signal: u, ...r }), ...n };
};
function IO(t, e) {
  const n = wt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qt = (t, e) => a.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lt = (t) => ["/partner/configs", ...t ? [t] : []], Wt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lt(t), queryFn: ({ signal: u }) => Qt(t, { signal: u, ...r }), ...n };
};
function wO(t, e) {
  const n = Wt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vt = (t, e, n) => a.patch(
  `/partner/configs/${t}`,
  e,
  n
), $t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Vt(o, u, n);
  }, ...e };
}, QO = (t) => {
  const e = $t(t);
  return i(e);
}, zt = (t) => a.get(
  "/partner/sync_webhooks",
  t
), Ht = () => ["/partner/sync_webhooks"], Yt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ht(), queryFn: ({ signal: o }) => zt({ signal: o, ...n }), ...e };
};
function LO(t) {
  const e = Yt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Xt = (t, e) => a.post(
  "/partner/sync_webhooks",
  t,
  e
), jt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Xt(o, n);
  }, ...e };
}, WO = (t) => {
  const e = jt(t);
  return i(e);
}, Zt = (t, e) => a.delete(
  `/partner/sync_webhooks/${t}`,
  e
), Jt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Zt(o, n);
  }, ...e };
}, VO = (t) => {
  const e = Jt(t);
  return i(e);
}, te = (t, e, n) => a.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), ee = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return te(o, u, n);
  }, ...e };
}, $O = (t) => {
  const e = ee(t);
  return i(e);
}, ne = (t, e) => a.post(
  "/auth/tokens",
  t,
  e
), re = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ne(o, n);
  }, ...e };
}, zO = (t) => {
  const e = re(t);
  return i(e);
}, se = (t, e) => a.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oe = (t) => ["/statements_v2", ...t ? [t] : []], ae = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oe(t), queryFn: ({ signal: u }) => se(t, { signal: u, ...r }), ...n };
};
function HO(t, e) {
  const n = ae(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ue = (t, e) => a.post(
  "/marketing/review",
  t,
  e
), ie = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ue(o, n);
  }, ...e };
}, YO = (t) => {
  const e = ie(t);
  return i(e);
}, ce = (t) => a.get(
  "/marketing/integrations",
  t
), ye = () => ["/marketing/integrations"], de = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ye(), queryFn: ({ signal: o }) => ce({ signal: o, ...n }), ...e };
};
function XO(t) {
  const e = de(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const pe = (t, e) => a.post(
  "/marketing/integrations",
  t,
  e
), _e = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pe(o, n);
  }, ...e };
}, jO = (t) => {
  const e = _e(t);
  return i(e);
}, me = (t, e) => a.delete(
  `/marketing/integrations/${t}`,
  e
), le = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: o } = s ?? {};
    return me(o, n);
  }, ...e };
}, ZO = (t) => {
  const e = le(t);
  return i(e);
}, ge = (t) => a.get(
  "/marketing/gtm_toolkit",
  t
), qe = () => ["/marketing/gtm_toolkit"], fe = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? qe(), queryFn: ({ signal: o }) => ge({ signal: o, ...n }), ...e };
};
function JO(t) {
  const e = fe(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Oe = (t) => a.get(
  "/marketing/paragon_token",
  t
), he = () => ["/marketing/paragon_token"], Ke = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? he(), queryFn: ({ signal: o }) => Oe({ signal: o, ...n }), ...e };
};
function th(t) {
  const e = Ke(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Pe = (t, e) => a.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), be = (t) => ["/beneficial_owners", ...t ? [t] : []], xe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? be(t), queryFn: ({ signal: u }) => Pe(t, { signal: u, ...r }), ...n };
};
function eh(t, e) {
  const n = xe(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Me = (t, e) => a.post(
  "/beneficial_owners",
  t,
  e
), ke = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Me(o, n);
  }, ...e };
}, nh = (t) => {
  const e = ke(t);
  return i(e);
}, Fe = (t, e) => a.delete(
  `/beneficial_owners/${t}`,
  e
), Re = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Fe(o, n);
  }, ...e };
}, rh = (t) => {
  const e = Re(t);
  return i(e);
}, Be = (t, e, n) => a.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), Ce = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Be(o, u, n);
  }, ...e };
}, sh = (t) => {
  const e = Ce(t);
  return i(e);
}, Ee = (t, e) => a.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Se = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], ve = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Se(t), queryFn: ({ signal: u }) => Ee(t, { signal: u, ...r }), ...n };
};
function oh(t, e) {
  const n = ve(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ae = (t, e) => a.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), De = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ae(o, n);
  }, ...e };
}, ah = (t) => {
  const e = De(t);
  return i(e);
}, Ue = (t, e) => a.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ne = (t) => ["/banks", ...t ? [t] : []], Ge = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ne(t), queryFn: ({ signal: u }) => Ue(t, { signal: u, ...r }), ...n };
};
function uh(t, e) {
  const n = Ge(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Te = (t, e) => a.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ie = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], we = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ie(t), queryFn: ({ signal: u }) => Te(t, { signal: u, ...r }), ...n };
};
function ih(t, e) {
  const n = we(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qe = (t, e) => a.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Le = (t) => ["/business_table_rows/mca", ...t ? [t] : []], We = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Le(t), queryFn: ({ signal: u }) => Qe(t, { signal: u, ...r }), ...n };
};
function ch(t, e) {
  const n = We(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ve = (t, e) => a.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $e = (t) => ["/business_table_rows/loan", ...t ? [t] : []], ze = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $e(t), queryFn: ({ signal: u }) => Ve(t, { signal: u, ...r }), ...n };
};
function yh(t, e) {
  const n = ze(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const He = (t, e) => a.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ye = (t) => ["/business_table_rows/card", ...t ? [t] : []], Xe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ye(t), queryFn: ({ signal: u }) => He(t, { signal: u, ...r }), ...n };
};
function dh(t, e) {
  const n = Xe(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const je = (t, e) => a.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ze = (t) => ["/business/officers", ...t ? [t] : []], Je = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ze(t), queryFn: ({ signal: u }) => je(t, { signal: u, ...r }), ...n };
};
function ph(t, e) {
  const n = Je(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tn = (t, e) => a.post(
  "/business/officers",
  t,
  e
), en = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return tn(o, n);
  }, ...e };
}, _h = (t) => {
  const e = en(t);
  return i(e);
}, nn = (t, e) => a.delete(
  `/business/officers/${t}`,
  e
), rn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return nn(o, n);
  }, ...e };
}, mh = (t) => {
  const e = rn(t);
  return i(e);
}, sn = (t, e, n) => a.patch(
  `/business/officers/${t}`,
  e,
  n
), on = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return sn(o, u, n);
  }, ...e };
}, lh = (t) => {
  const e = on(t);
  return i(e);
}, an = (t) => a.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), un = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => an(n), ...e };
}, gh = (t) => {
  const e = un(t);
  return i(e);
}, cn = (t, e) => a.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yn = (t) => ["/dataset_uploads", ...t ? [t] : []], dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yn(t), queryFn: ({ signal: u }) => cn(t, { signal: u, ...r }), ...n };
};
function qh(t, e) {
  const n = dn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const pn = (t, e) => a.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _n = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _n(t), queryFn: ({ signal: u }) => pn(t, { signal: u, ...r }), ...n };
};
function fh(t, e) {
  const n = mn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ln = (t, e) => a.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gn = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], qn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gn(t), queryFn: ({ signal: u }) => ln(t, { signal: u, ...r }), ...n };
};
function Oh(t, e) {
  const n = qn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fn = (t, e) => a.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), On = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? On(t), queryFn: ({ signal: u }) => fn(t, { signal: u, ...r }), ...n };
};
function hh(t, e) {
  const n = hn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Kn = (t, e) => a.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pn = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], bn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pn(t), queryFn: ({ signal: u }) => Kn(t, { signal: u, ...r }), ...n };
};
function Kh(t, e) {
  const n = bn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xn = (t, e) => a.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), Mn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xn(o, n);
  }, ...e };
}, Ph = (t) => {
  const e = Mn(t);
  return i(e);
}, kn = (t, e) => a.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fn = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], Rn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fn(t), queryFn: ({ signal: u }) => kn(t, { signal: u, ...r }), ...n };
};
function bh(t, e) {
  const n = Rn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Bn = (t, e) => a.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), Cn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bn(o, n);
  }, ...e };
}, xh = (t) => {
  const e = Cn(t);
  return i(e);
}, En = (t, e) => a.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], vn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sn(t), queryFn: ({ signal: u }) => En(t, { signal: u, ...r }), ...n };
};
function Mh(t, e) {
  const n = vn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => a.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Dn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return An(o, n);
  }, ...e };
}, kh = (t) => {
  const e = Dn(t);
  return i(e);
}, Un = (t, e) => a.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), Nn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Un(o, n);
  }, ...e };
}, Fh = (t) => {
  const e = Nn(t);
  return i(e);
}, Gn = (t, e) => a.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Tn = (t) => ["/dataingestion/schema", ...t ? [t] : []], In = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tn(t), queryFn: ({ signal: u }) => Gn(t, { signal: u, ...r }), ...n };
};
function Rh(t, e) {
  const n = In(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wn = (t, e) => a.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qn = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], Ln = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qn(t), queryFn: ({ signal: u }) => wn(t, { signal: u, ...r }), ...n };
};
function Bh(t, e) {
  const n = Ln(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wn = (t, e) => a.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Vn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wn(o, n);
  }, ...e };
}, Ch = (t) => {
  const e = Vn(t);
  return i(e);
}, $n = (t, e) => a.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), zn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $n(o, n);
  }, ...e };
}, Eh = (t) => {
  const e = zn(t);
  return i(e);
}, Hn = (t, e) => a.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yn = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], Xn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yn(t), queryFn: ({ signal: u }) => Hn(t, { signal: u, ...r }), ...n };
};
function Sh(t, e) {
  const n = Xn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jn = (t, e) => a.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zn = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], Jn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zn(t), queryFn: ({ signal: u }) => jn(t, { signal: u, ...r }), ...n };
};
function vh(t, e) {
  const n = Jn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tr = (t, e) => a.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), er = (t) => ["/capital_products", ...t ? [t] : []], nr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? er(t), queryFn: ({ signal: u }) => tr(t, { signal: u, ...r }), ...n };
};
function Ah(t, e) {
  const n = nr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rr = (t, e) => a.post(
  "/capital_products",
  t,
  e
), sr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return rr(o, n);
  }, ...e };
}, Dh = (t) => {
  const e = sr(t);
  return i(e);
}, or = (t, e, n) => a.patch(
  `/capital_products/${t}`,
  e,
  n
), ar = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return or(o, u, n);
  }, ...e };
}, Uh = (t) => {
  const e = ar(t);
  return i(e);
}, ur = (t, e) => a.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ir = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], cr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ir(t), queryFn: ({ signal: u }) => ur(t, { signal: u, ...r }), ...n };
};
function Nh(t, e) {
  const n = cr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yr = (t, e) => a.post(
  "/capital_product_offer_collections",
  t,
  e
), dr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yr(o, n);
  }, ...e };
}, Gh = (t) => {
  const e = dr(t);
  return i(e);
}, pr = (t, e) => a.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _r = (t) => ["/capital_product_activities", ...t ? [t] : []], mr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _r(t), queryFn: ({ signal: u }) => pr(t, { signal: u, ...r }), ...n };
};
function Th(t, e) {
  const n = mr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lr = (t, e) => a.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gr = (t) => ["/capital_product_applications", ...t ? [t] : []], qr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gr(t), queryFn: ({ signal: u }) => lr(t, { signal: u, ...r }), ...n };
};
function Ih(t, e) {
  const n = qr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fr = (t, e) => a.post(
  "/capital_product_applications",
  t,
  e
), Or = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fr(o, n);
  }, ...e };
}, wh = (t) => {
  const e = Or(t);
  return i(e);
}, hr = (t, e) => a.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kr = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], Pr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kr(t), queryFn: ({ signal: u }) => hr(t, { signal: u, ...r }), ...n };
};
function Qh(t, e) {
  const n = Pr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const br = (t, e) => a.post(
  "/net_top_up",
  t,
  e
), xr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return br(o, n);
  }, ...e };
}, Lh = (t) => {
  const e = xr(t);
  return i(e);
}, Mr = (t, e) => a.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), kr = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], Fr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kr(t), queryFn: ({ signal: u }) => Mr(t, { signal: u, ...r }), ...n };
};
function Wh(t, e) {
  const n = Fr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rr = (t, e) => a.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Br = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], Cr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Br(t), queryFn: ({ signal: u }) => Rr(t, { signal: u, ...r }), ...n };
};
function Vh(t, e) {
  const n = Cr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Er = (t) => a.get(
  "/benchmark_capital_products_ownerships",
  t
), Sr = () => ["/benchmark_capital_products_ownerships"], vr = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Sr(), queryFn: ({ signal: o }) => Er({ signal: o, ...n }), ...e };
};
function $h(t) {
  const e = vr(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ar = (t, e) => a.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], Ur = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dr(t), queryFn: ({ signal: u }) => Ar(t, { signal: u, ...r }), ...n };
};
function zh(t, e) {
  const n = Ur(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nr = (t, e) => a.post(
  "/offered_capital_product_discount",
  t,
  e
), Gr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Nr(o, n);
  }, ...e };
}, Hh = (t) => {
  const e = Gr(t);
  return i(e);
}, Tr = (t, e) => a.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ir = (t) => ["/capital_product_intents", ...t ? [t] : []], wr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ir(t), queryFn: ({ signal: u }) => Tr(t, { signal: u, ...r }), ...n };
};
function Yh(t, e) {
  const n = wr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qr = (t, e) => a.post(
  "/capital_product_intents",
  t,
  e
), Lr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Qr(o, n);
  }, ...e };
}, Xh = (t) => {
  const e = Lr(t);
  return i(e);
}, Wr = (t, e, n) => a.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), Vr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Wr(o, u, n);
  }, ...e };
}, jh = (t) => {
  const e = Vr(t);
  return i(e);
}, $r = (t, e) => a.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zr = (t) => ["/counteroffer", ...t ? [t] : []], Hr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zr(t), queryFn: ({ signal: u }) => $r(t, { signal: u, ...r }), ...n };
};
function Zh(t, e) {
  const n = Hr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yr = (t, e) => a.post(
  "/capital_product_super_applications",
  t,
  e
), Xr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Yr(o, n);
  }, ...e };
}, Jh = (t) => {
  const e = Xr(t);
  return i(e);
}, jr = (t, e) => a.post(
  "/capital_product_super_application_actions",
  t,
  e
), Zr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jr(o, n);
  }, ...e };
}, tK = (t) => {
  const e = Zr(t);
  return i(e);
}, Jr = (t, e, n) => a.get(
  `/capital_product_offer_chunk_calculated/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), ts = (t, e) => [`/capital_product_offer_chunk_calculated/${t}`, ...e ? [e] : []], es = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? ts(t, e), queryFn: ({ signal: y }) => Jr(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function eK(t, e, n) {
  const r = es(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const ns = (t, e) => a.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rs = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], ss = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rs(t), queryFn: ({ signal: u }) => ns(t, { signal: u, ...r }), ...n };
};
function nK(t, e) {
  const n = ss(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const os = (t, e) => a.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), as = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return os(o, n);
  }, ...e };
}, rK = (t) => {
  const e = as(t);
  return i(e);
}, us = (t, e) => a.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), is = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], cs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? is(t), queryFn: ({ signal: u }) => us(t, { signal: u, ...r }), ...n };
};
function sK(t, e) {
  const n = cs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ys = (t, e, n) => a.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), ds = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ys(o, u, n);
  }, ...e };
}, oK = (t) => {
  const e = ds(t);
  return i(e);
}, ps = (t, e) => a.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _s = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _s(t), queryFn: ({ signal: u }) => ps(t, { signal: u, ...r }), ...n };
};
function aK(t, e) {
  const n = ms(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ls = (t, e) => a.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gs = (t) => ["/funding/bank_account_info", ...t ? [t] : []], qs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gs(t), queryFn: ({ signal: u }) => ls(t, { signal: u, ...r }), ...n };
};
function uK(t, e) {
  const n = qs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fs = (t, e) => a.post(
  "/funding/bank_account_info",
  t,
  e
), Os = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fs(o, n);
  }, ...e };
}, iK = (t) => {
  const e = Os(t);
  return i(e);
}, hs = (t, e, n) => a.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), Ks = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return hs(o, u, n);
  }, ...e };
}, cK = (t) => {
  const e = Ks(t);
  return i(e);
}, Ps = (t, e) => a.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bs = (t) => ["/funding/accounts", ...t ? [t] : []], xs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bs(t), queryFn: ({ signal: u }) => Ps(t, { signal: u, ...r }), ...n };
};
function yK(t, e) {
  const n = xs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ms = (t, e) => a.post(
  "/funding/accounts",
  t,
  e
), ks = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ms(o, n);
  }, ...e };
}, dK = (t) => {
  const e = ks(t);
  return i(e);
}, Fs = (t, e) => a.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rs = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], Bs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rs(t), queryFn: ({ signal: u }) => Fs(t, { signal: u, ...r }), ...n };
};
function pK(t, e) {
  const n = Bs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cs = (t, e) => a.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), Es = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Cs(o, n);
  }, ...e };
}, _K = (t) => {
  const e = Es(t);
  return i(e);
}, Ss = (t, e, n) => a.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), vs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Ss(o, u, n);
  }, ...e };
}, mK = (t) => {
  const e = vs(t);
  return i(e);
}, As = (t, e) => a.get(
  "/kyc_retry_fields",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ds = (t) => ["/kyc_retry_fields", ...t ? [t] : []], Us = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ds(t), queryFn: ({ signal: u }) => As(t, { signal: u, ...r }), ...n };
};
function lK(t, e) {
  const n = Us(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ns = (t, e) => a.post(
  "/kyc_retry",
  t,
  e
), Gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ns(o, n);
  }, ...e };
}, gK = (t) => {
  const e = Gs(t);
  return i(e);
}, Ts = (t, e) => a.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Is = (t) => ["/onboarding/states", ...t ? [t] : []], ws = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Is(t), queryFn: ({ signal: u }) => Ts(t, { signal: u, ...r }), ...n };
};
function qK(t, e) {
  const n = ws(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qs = (t, e) => a.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ls = (t) => ["/onboarding/info_needed", ...t ? [t] : []], Ws = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ls(t), queryFn: ({ signal: u }) => Qs(t, { signal: u, ...r }), ...n };
};
function fK(t, e) {
  const n = Ws(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vs = (t, e) => a.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $s = (t) => ["/business_identities", ...t ? [t] : []], zs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $s(t), queryFn: ({ signal: u }) => Vs(t, { signal: u, ...r }), ...n };
};
function OK(t, e) {
  const n = zs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hs = (t, e, n) => a.patch(
  `/business_identities/${t}`,
  e,
  n
), Ys = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Hs(o, u, n);
  }, ...e };
}, hK = (t) => {
  const e = Ys(t);
  return i(e);
}, Xs = (t, e) => a.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), js = (t) => ["/owners", ...t ? [t] : []], Zs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? js(t), queryFn: ({ signal: u }) => Xs(t, { signal: u, ...r }), ...n };
};
function KK(t, e) {
  const n = Zs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Js = (t, e, n) => a.patch(
  `/owners/${t}`,
  e,
  n
), to = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Js(o, u, n);
  }, ...e };
}, PK = (t) => {
  const e = to(t);
  return i(e);
}, eo = (t, e) => a.post(
  "/business",
  t,
  e
), no = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return eo(o, n);
  }, ...e };
}, bK = (t) => {
  const e = no(t);
  return i(e);
}, ro = (t, e) => a.post(
  "/merchant_bank/business",
  t,
  e
), so = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ro(o, n);
  }, ...e };
}, xK = (t) => {
  const e = so(t);
  return i(e);
}, oo = (t) => a.post(
  "/amazon_redirect_url",
  void 0,
  t
), ao = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => oo(n), ...e };
}, MK = (t) => {
  const e = ao(t);
  return i(e);
}, uo = (t, e) => a.post(
  "/spd",
  t,
  e
), io = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return uo(o, n);
  }, ...e };
}, kK = (t) => {
  const e = io(t);
  return i(e);
}, co = (t, e) => a.post(
  "/capital_product_business",
  t,
  e
), yo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return co(o, n);
  }, ...e };
}, FK = (t) => {
  const e = yo(t);
  return i(e);
}, po = (t, e) => a.patch(
  "/flex_loan_age",
  t,
  e
), _o = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return po(o, n);
  }, ...e };
}, RK = (t) => {
  const e = _o(t);
  return i(e);
}, mo = (t, e) => a.patch(
  "/flex_loan_ownership",
  t,
  e
), lo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mo(o, n);
  }, ...e };
}, BK = (t) => {
  const e = lo(t);
  return i(e);
}, go = (t, e) => a.patch(
  "/flex_loan_fund",
  t,
  e
), qo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return go(o, n);
  }, ...e };
}, CK = (t) => {
  const e = qo(t);
  return i(e);
}, fo = (t, e) => a.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Oo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: o } = s ?? {};
    return fo(o, n);
  }, ...e };
}, EK = (t) => {
  const e = Oo(t);
  return i(e);
}, ho = (t, e) => a.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ko = (t) => ["/cards", ...t ? [t] : []], Po = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ko(t), queryFn: ({ signal: u }) => ho(t, { signal: u, ...r }), ...n };
};
function SK(t, e) {
  const n = Po(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bo = (t, e) => a.post(
  "/cards",
  t,
  e
), xo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bo(o, n);
  }, ...e };
}, vK = (t) => {
  const e = xo(t);
  return i(e);
}, Mo = (t, e) => a.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ko = (t) => ["/businesses/products", ...t ? [t] : []], Fo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ko(t), queryFn: ({ signal: u }) => Mo(t, { signal: u, ...r }), ...n };
};
function AK(t, e) {
  const n = Fo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ro = (t, e) => a.post(
  "/business/auth_link",
  t,
  e
), Bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ro(o, n);
  }, ...e };
}, DK = (t) => {
  const e = Bo(t);
  return i(e);
}, Co = (t, e) => a.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eo = (t) => ["/document_upload/group", ...t ? [t] : []], So = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eo(t), queryFn: ({ signal: u }) => Co(t, { signal: u, ...r }), ...n };
};
function UK(t, e) {
  const n = So(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vo = (t, e) => a.post(
  "/document_upload/group",
  t,
  e
), Ao = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vo(o, n);
  }, ...e };
}, NK = (t) => {
  const e = Ao(t);
  return i(e);
}, Do = (t, e) => a.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uo = (t) => ["/document_upload/list", ...t ? [t] : []], No = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uo(t), queryFn: ({ signal: u }) => Do(t, { signal: u, ...r }), ...n };
};
function GK(t, e) {
  const n = No(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Go = (t, e) => a.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), To = (t) => ["/restriction", ...t ? [t] : []], Io = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? To(t), queryFn: ({ signal: u }) => Go(t, { signal: u, ...r }), ...n };
};
function TK(t, e) {
  const n = Io(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wo = (t, e) => a.post(
  "/restriction",
  t,
  e
), Qo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return wo(o, n);
  }, ...e };
}, IK = (t) => {
  const e = Qo(t);
  return i(e);
}, Lo = (t, e) => a.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wo = (t) => ["/restrictions/details", ...t ? [t] : []], Vo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wo(t), queryFn: ({ signal: u }) => Lo(t, { signal: u, ...r }), ...n };
};
function wK(t, e) {
  const n = Vo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $o = (t, e) => a.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zo = (t) => ["/liens", ...t ? [t] : []], Ho = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zo(t), queryFn: ({ signal: u }) => $o(t, { signal: u, ...r }), ...n };
};
function QK(t, e) {
  const n = Ho(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yo = (t, e, n) => a.patch(
  `/liens/${t}`,
  e,
  n
), Xo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Yo(o, u, n);
  }, ...e };
}, LK = (t) => {
  const e = Xo(t);
  return i(e);
}, jo = (t, e) => a.post(
  "/opt_ins/trigger",
  t,
  e
), Zo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jo(o, n);
  }, ...e };
}, WK = (t) => {
  const e = Zo(t);
  return i(e);
}, Jo = (t, e) => a.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ta = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], ea = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ta(t), queryFn: ({ signal: u }) => Jo(t, { signal: u, ...r }), ...n };
};
function VK(t, e) {
  const n = ea(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const na = (t, e) => a.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ra = (t) => ["/parafinder/businesses", ...t ? [t] : []], sa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ra(t), queryFn: ({ signal: u }) => na(t, { signal: u, ...r }), ...n };
};
function $K(t, e) {
  const n = sa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oa = (t) => a.get(
  "/parafinder/business_types",
  t
), aa = () => ["/parafinder/business_types"], ua = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? aa(), queryFn: ({ signal: o }) => oa({ signal: o, ...n }), ...e };
};
function zK(t) {
  const e = ua(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ia = (t, e) => a.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ca = (t) => ["/parafinder/summary", ...t ? [t] : []], ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ca(t), queryFn: ({ signal: u }) => ia(t, { signal: u, ...r }), ...n };
};
function HK(t, e) {
  const n = ya(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const da = (t, e) => a.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pa = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], _a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pa(t), queryFn: ({ signal: u }) => da(t, { signal: u, ...r }), ...n };
};
function YK(t, e) {
  const n = _a(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ma = (t, e) => a.get(
  `/merchant_bank/accounts/${t}`,
  e
), la = (t) => [`/merchant_bank/accounts/${t}`], ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? la(t), queryFn: ({ signal: u }) => ma(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function XK(t, e) {
  const n = ga(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qa = (t, e) => a.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), fa = (t) => [`/merchant_bank/accounts/${t}/balances`], Oa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fa(t), queryFn: ({ signal: u }) => qa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function jK(t, e) {
  const n = Oa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ha = (t, e) => a.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ka = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Pa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ka(t), queryFn: ({ signal: u }) => ha(t, { signal: u, ...r }), ...n };
};
function ZK(t, e) {
  const n = Pa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ba = (t, e) => a.post(
  "/merchant_bank/accounts",
  t,
  e
), xa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ba(o, n);
  }, ...e };
}, JK = (t) => {
  const e = xa(t);
  return i(e);
}, Ma = (t, e) => a.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), ka = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ma(o, n);
  }, ...e };
}, tP = (t) => {
  const e = ka(t);
  return i(e);
}, Fa = (t, e, n) => a.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ra = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], Ba = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ra(t, e), queryFn: ({ signal: y }) => Fa(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function eP(t, e, n) {
  const r = Ba(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Ca = (t, e) => a.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ea = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], Sa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ea(t), queryFn: ({ signal: u }) => Ca(t, { signal: u, ...r }), ...n };
};
function nP(t, e) {
  const n = Sa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const va = (t) => a.get(
  "/merchant_bank/transfer_fees",
  t
), Aa = () => ["/merchant_bank/transfer_fees"], Da = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Aa(), queryFn: ({ signal: o }) => va({ signal: o, ...n }), ...e };
};
function rP(t) {
  const e = Da(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ua = (t, e) => a.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Na = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], Ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Na(t), queryFn: ({ signal: u }) => Ua(t, { signal: u, ...r }), ...n };
};
function sP(t, e) {
  const n = Ga(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ta = (t) => a.get(
  "/merchant_bank/interest",
  t
), Ia = () => ["/merchant_bank/interest"], wa = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ia(), queryFn: ({ signal: o }) => Ta({ signal: o, ...n }), ...e };
};
function oP(t) {
  const e = wa(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Qa = (t, e) => a.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), La = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], Wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? La(t), queryFn: ({ signal: u }) => Qa(t, { signal: u, ...r }), ...n };
};
function aP(t, e) {
  const n = Wa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Va = (t, e) => a.post(
  "/merchant_bank/recipients",
  t,
  e
), $a = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Va(o, n);
  }, ...e };
}, uP = (t) => {
  const e = $a(t);
  return i(e);
}, za = (t, e) => a.get(
  `/merchant_bank/recipients/${t}`,
  e
), Ha = (t) => [`/merchant_bank/recipients/${t}`], Ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ha(t), queryFn: ({ signal: u }) => za(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function iP(t, e) {
  const n = Ya(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xa = (t, e) => a.delete(
  `/merchant_bank/recipients/${t}`,
  e
), ja = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Xa(o, n);
  }, ...e };
}, cP = (t) => {
  const e = ja(t);
  return i(e);
}, Za = (t, e, n) => a.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), Ja = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Za(o, u, n);
  }, ...e };
}, yP = (t) => {
  const e = Ja(t);
  return i(e);
}, tu = (t, e, n) => a.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), eu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return tu(o, u, n);
  }, ...e };
}, dP = (t) => {
  const e = eu(t);
  return i(e);
}, nu = (t, e) => a.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), ru = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return nu(o, n);
  }, ...e };
}, pP = (t) => {
  const e = ru(t);
  return i(e);
}, su = (t, e, n) => a.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), ou = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return su(o, u, n);
  }, ...e };
}, _P = (t) => {
  const e = ou(t);
  return i(e);
}, au = (t, e) => a.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), uu = (t) => ["/merchant_bank/cards", ...t ? [t] : []], iu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? uu(t), queryFn: ({ signal: u }) => au(t, { signal: u, ...r }), ...n };
};
function mP(t, e) {
  const n = iu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const cu = (t, e) => a.post(
  "/merchant_bank/cards",
  t,
  e
), yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return cu(o, n);
  }, ...e };
}, lP = (t) => {
  const e = yu(t);
  return i(e);
}, du = (t, e, n) => a.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return du(o, u, n);
  }, ...e };
}, gP = (t) => {
  const e = pu(t);
  return i(e);
}, _u = (t, e) => a.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mu = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], lu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mu(t), queryFn: ({ signal: u }) => _u(t, { signal: u, ...r }), ...n };
};
function qP(t, e) {
  const n = lu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gu = (t, e) => a.post(
  "/merchant_bank/card/activate",
  t,
  e
), qu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return gu(o, n);
  }, ...e };
}, fP = (t) => {
  const e = qu(t);
  return i(e);
}, fu = (t, e) => a.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ou = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], hu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ou(t), queryFn: ({ signal: u }) => fu(t, { signal: u, ...r }), ...n };
};
function OP(t, e) {
  const n = hu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ku = (t, e) => a.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ku(o, n);
  }, ...e };
}, hP = (t) => {
  const e = Pu(t);
  return i(e);
}, bu = (t, e) => a.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], Mu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xu(t), queryFn: ({ signal: u }) => bu(t, { signal: u, ...r }), ...n };
};
function KP(t, e) {
  const n = Mu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ku = (t, e) => a.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Fu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ku(o, n);
  }, ...e };
}, PP = (t) => {
  const e = Fu(t);
  return i(e);
}, Ru = (t, e) => a.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), Bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ru(o, n);
  }, ...e };
}, bP = (t) => {
  const e = Bu(t);
  return i(e);
}, Cu = (t, e) => a.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], Su = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eu(t), queryFn: ({ signal: u }) => Cu(t, { signal: u, ...r }), ...n };
};
function xP(t, e) {
  const n = Su(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vu = (t, e) => a.post(
  "/merchant_bank/card/disputes",
  t,
  e
), Au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vu(o, n);
  }, ...e };
}, MP = (t) => {
  const e = Au(t);
  return i(e);
}, Du = (t, e) => a.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), Uu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Du(o, n);
  }, ...e };
}, kP = (t) => {
  const e = Uu(t);
  return i(e);
}, Nu = (t, e) => a.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), Gu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Nu(o, n);
  }, ...e };
}, FP = (t) => {
  const e = Gu(t);
  return i(e);
}, Tu = (t, e) => a.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Iu = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Iu(t), queryFn: ({ signal: u }) => Tu(t, { signal: u, ...r }), ...n };
};
function RP(t, e) {
  const n = wu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qu = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), Lu = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], Wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lu(t), queryFn: ({ signal: u }) => Qu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function BP(t, e) {
  const n = Wu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vu = (t, e) => a.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $u = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], zu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $u(t), queryFn: ({ signal: u }) => Vu(t, { signal: u, ...r }), ...n };
};
function CP(t, e) {
  const n = zu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hu = (t, e) => a.post(
  "/merchant_bank/external_accounts",
  t,
  e
), Yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hu(o, n);
  }, ...e };
}, EP = (t) => {
  const e = Yu(t);
  return i(e);
}, Xu = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), ju = (t) => [`/merchant_bank/external_accounts/${t}`], Zu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ju(t), queryFn: ({ signal: u }) => Xu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function SP(t, e) {
  const n = Zu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ju = (t, e) => a.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), ti = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Ju(o, n);
  }, ...e };
}, vP = (t) => {
  const e = ti(t);
  return i(e);
}, ei = (t, e, n) => a.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), ni = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ei(o, u, n);
  }, ...e };
}, AP = (t) => {
  const e = ni(t);
  return i(e);
}, ri = (t, e) => a.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ri(o, n);
  }, ...e };
}, DP = (t) => {
  const e = si(t);
  return i(e);
}, oi = (t, e, n) => a.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), ai = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return oi(o, u, n);
  }, ...e };
}, UP = (t) => {
  const e = ai(t);
  return i(e);
}, ui = (t, e) => a.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ii = (t) => ["/debts", ...t ? [t] : []], ci = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ii(t), queryFn: ({ signal: u }) => ui(t, { signal: u, ...r }), ...n };
};
function NP(t, e) {
  const n = ci(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yi = (t, e) => a.post(
  "/debts",
  t,
  e
), di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yi(o, n);
  }, ...e };
}, GP = (t) => {
  const e = di(t);
  return i(e);
}, pi = (t, e) => a.post(
  "/debts/check_complete",
  t,
  e
), _i = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pi(o, n);
  }, ...e };
}, TP = (t) => {
  const e = _i(t);
  return i(e);
}, mi = (t, e) => a.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), li = (t) => ["/debt_checks", ...t ? [t] : []], gi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? li(t), queryFn: ({ signal: u }) => mi(t, { signal: u, ...r }), ...n };
};
function IP(t, e) {
  const n = gi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qi = (t, e) => a.post(
  "/card/close_account",
  t,
  e
), fi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qi(o, n);
  }, ...e };
}, wP = (t) => {
  const e = fi(t);
  return i(e);
}, Oi = (t, e) => a.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hi = (t) => ["/financial_reviews", ...t ? [t] : []], Ki = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hi(t), queryFn: ({ signal: u }) => Oi(t, { signal: u, ...r }), ...n };
};
function QP(t, e) {
  const n = Ki(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pi = (t, e) => a.post(
  "/financial_reviews",
  t,
  e
), bi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Pi(o, n);
  }, ...e };
}, LP = (t) => {
  const e = bi(t);
  return i(e);
}, xi = (t, e) => a.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mi = (t) => ["/accounting_info", ...t ? [t] : []], ki = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mi(t), queryFn: ({ signal: u }) => xi(t, { signal: u, ...r }), ...n };
};
function WP(t, e) {
  const n = ki(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fi = (t, e) => a.post(
  "/accounting_info",
  t,
  e
), Ri = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fi(o, n);
  }, ...e };
}, VP = (t) => {
  const e = Ri(t);
  return i(e);
}, Bi = (t, e) => a.post(
  "/tax_records",
  t,
  e
), Ci = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bi(o, n);
  }, ...e };
}, $P = (t) => {
  const e = Ci(t);
  return i(e);
}, Ei = (t, e) => a.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Si = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], vi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Si(t), queryFn: ({ signal: u }) => Ei(t, { signal: u, ...r }), ...n };
};
function zP(t, e) {
  const n = vi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ai = (t, e) => a.post(
  "/officer_subsidiary_records",
  t,
  e
), Di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ai(o, n);
  }, ...e };
}, HP = (t) => {
  const e = Di(t);
  return i(e);
}, Ui = (t) => a.get(
  "/analytics/cube_token",
  t
), Ni = () => ["/analytics/cube_token"], Gi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ni(), queryFn: ({ signal: o }) => Ui({ signal: o, ...n }), ...e };
};
function YP(t) {
  const e = Gi(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ti = (t, e) => a.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ii = (t) => ["/logs/api_request", ...t ? [t] : []], wi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ii(t), queryFn: ({ signal: u }) => Ti(t, { signal: u, ...r }), ...n };
};
function XP(t, e) {
  const n = wi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qi = (t) => a.get(
  "/marketing/offer_csvs",
  t
), Li = () => ["/marketing/offer_csvs"], Wi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Li(), queryFn: ({ signal: o }) => Qi({ signal: o, ...n }), ...e };
};
function jP(t) {
  const e = Wi(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Vi = (t) => a.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), $i = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Vi(n), ...e };
}, ZP = (t) => {
  const e = $i(t);
  return i(e);
}, zi = (t, e) => a.get(
  "/marketing/emails/batch",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hi = (t) => ["/marketing/emails/batch", ...t ? [t] : []], Yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hi(t), queryFn: ({ signal: u }) => zi(t, { signal: u, ...r }), ...n };
};
function JP(t, e) {
  const n = Yi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xi = (t, e) => a.post(
  "/marketing/emails/batch",
  t,
  e
), ji = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Xi(o, n);
  }, ...e };
}, tb = (t) => {
  const e = ji(t);
  return i(e);
}, Zi = (t, e) => a.get(
  "/marketing/emails/scheduled_marketing_config",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ji = (t) => ["/marketing/emails/scheduled_marketing_config", ...t ? [t] : []], tc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ji(t), queryFn: ({ signal: u }) => Zi(t, { signal: u, ...r }), ...n };
};
function eb(t, e) {
  const n = tc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ec = (t, e) => a.post(
  "/marketing/emails/scheduled_marketing_config",
  t,
  e
), nc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ec(o, n);
  }, ...e };
}, nb = (t) => {
  const e = nc(t);
  return i(e);
}, rc = (t, e) => a.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sc = (t) => ["/businesses/notifications", ...t ? [t] : []], oc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sc(t), queryFn: ({ signal: u }) => rc(t, { signal: u, ...r }), ...n };
};
function rb(t, e) {
  const n = oc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ac = (t, e) => a.post(
  "/businesses/notifications",
  t,
  e
), uc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ac(o, n);
  }, ...e };
}, sb = (t) => {
  const e = uc(t);
  return i(e);
}, ic = (t, e) => a.post(
  "/terms_of_service_mark_shown",
  t,
  e
), cc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ic(o, n);
  }, ...e };
}, ob = (t) => {
  const e = cc(t);
  return i(e);
}, yc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), dc = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], pc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? dc(t, e), queryFn: ({ signal: y }) => yc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ab(t, e, n) {
  const r = pc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const _c = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), mc = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], lc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mc(t), queryFn: ({ signal: u }) => _c(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ub(t, e) {
  const n = lc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), qc = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], fc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qc(t), queryFn: ({ signal: u }) => gc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ib(t, e) {
  const n = fc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Oc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), hc = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], Kc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hc(t), queryFn: ({ signal: u }) => Oc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function cb(t, e) {
  const n = Kc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), bc = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], xc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? bc(t, e), queryFn: ({ signal: y }) => Pc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function yb(t, e, n) {
  const r = xc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Mc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), kc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Fc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? kc(t, e), queryFn: ({ signal: y }) => Mc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function db(t, e, n) {
  const r = Fc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Rc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Bc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Cc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Bc(t, e), queryFn: ({ signal: y }) => Rc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function pb(t, e, n) {
  const r = Cc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Ec = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), Sc = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sc(t), queryFn: ({ signal: u }) => Ec(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _b(t, e) {
  const n = vc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ac = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], Uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dc(t), queryFn: ({ signal: u }) => Ac(t, { signal: u, ...r }), ...n };
};
function mb(t, e) {
  const n = Uc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nc = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], Tc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gc(t), queryFn: ({ signal: u }) => Nc(t, { signal: u, ...r }), ...n };
};
function lb(t, e) {
  const n = Tc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ic = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], Qc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wc(t), queryFn: ({ signal: u }) => Ic(t, { signal: u, ...r }), ...n };
};
function gb(t, e) {
  const n = Qc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lc = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], Vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wc(t), queryFn: ({ signal: u }) => Lc(t, { signal: u, ...r }), ...n };
};
function qb(t, e) {
  const n = Vc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $c = (t, e) => a.get(
  "/partner_dashboard/orders_tab/orders_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zc = (t) => ["/partner_dashboard/orders_tab/orders_table_rows/count", ...t ? [t] : []], Hc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zc(t), queryFn: ({ signal: u }) => $c(t, { signal: u, ...r }), ...n };
};
function fb(t, e) {
  const n = Hc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yc = (t, e) => a.get(
  "/partner_dashboard/orders_tab/orders_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xc = (t) => ["/partner_dashboard/orders_tab/orders_table_rows", ...t ? [t] : []], jc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xc(t), queryFn: ({ signal: u }) => Yc(t, { signal: u, ...r }), ...n };
};
function Ob(t, e) {
  const n = jc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zc = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], ty = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jc(t), queryFn: ({ signal: u }) => Zc(t, { signal: u, ...r }), ...n };
};
function hb(t, e) {
  const n = ty(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ey = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ny = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], ry = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ny(t), queryFn: ({ signal: u }) => ey(t, { signal: u, ...r }), ...n };
};
function Kb(t, e) {
  const n = ry(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const sy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], ay = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oy(t), queryFn: ({ signal: u }) => sy(t, { signal: u, ...r }), ...n };
};
function Pb(t, e) {
  const n = ay(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const uy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), iy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], cy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? iy(t), queryFn: ({ signal: u }) => uy(t, { signal: u, ...r }), ...n };
};
function bb(t, e) {
  const n = cy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yy = (t) => a.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), dy = () => ["/partner_dashboard/platform_tab/partner_support_info"], py = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? dy(), queryFn: ({ signal: o }) => yy({ signal: o, ...n }), ...e };
};
function xb(t) {
  const e = py(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const _y = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), my = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], ly = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? my(t), queryFn: ({ signal: u }) => _y(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Mb(t, e) {
  const n = ly(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gy = (t) => a.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), qy = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], fy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? qy(), queryFn: ({ signal: o }) => gy({ signal: o, ...n }), ...e };
};
function kb(t) {
  const e = fy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Oy = (t, e) => a.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hy = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], Ky = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hy(t), queryFn: ({ signal: u }) => Oy(t, { signal: u, ...r }), ...n };
};
function Fb(t, e) {
  const n = Ky(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Py = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  t,
  e
), by = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Py(o, n);
  }, ...e };
}, Rb = (t) => {
  const e = by(t);
  return i(e);
}, xy = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), My = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xy(o, n);
  }, ...e };
}, Bb = (t) => {
  const e = My(t);
  return i(e);
}, ky = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), Fy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ky(o, n);
  }, ...e };
}, Cb = (t) => {
  const e = Fy(t);
  return i(e);
}, Ry = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions_for_event_type",
  t,
  e
), By = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ry(o, n);
  }, ...e };
}, Eb = (t) => {
  const e = By(t);
  return i(e);
}, Cy = (t) => a.get(
  "/partner_dashboard/crm/integration",
  t
), Ey = () => ["/partner_dashboard/crm/integration"], Sy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ey(), queryFn: ({ signal: o }) => Cy({ signal: o, ...n }), ...e };
};
function Sb(t) {
  const e = Sy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const vy = (t) => a.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Ay = () => ["/partner_dashboard/valid_object_id_prefixes"], Dy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ay(), queryFn: ({ signal: o }) => vy({ signal: o, ...n }), ...e };
};
function vb(t) {
  const e = Dy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Uy = (t, e) => a.get(
  `/partner_dashboard/business/owner_auth/${t}`,
  e
), Ny = (t) => [`/partner_dashboard/business/owner_auth/${t}`], Gy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ny(t), queryFn: ({ signal: u }) => Uy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Ab(t, e) {
  const n = Gy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ty = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/created",
  t,
  e
), Iy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ty(o, n);
  }, ...e };
}, Db = (t) => {
  const e = Iy(t);
  return i(e);
}, wy = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/closed",
  t,
  e
), Qy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return wy(o, n);
  }, ...e };
}, Ub = (t) => {
  const e = Qy(t);
  return i(e);
}, Ly = (t, e) => a.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wy = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], Vy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wy(t), queryFn: ({ signal: u }) => Ly(t, { signal: u, ...r }), ...n };
};
function Nb(t, e) {
  const n = Vy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $y = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), zy = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], Hy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zy(t), queryFn: ({ signal: u }) => $y(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Gb(t, e) {
  const n = Hy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yy = (t, e, n) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Xy = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], jy = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Xy(t, e), queryFn: ({ signal: y }) => Yy(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Tb(t, e, n) {
  const r = jy(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Zy = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), Jy = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], td = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jy(t), queryFn: ({ signal: u }) => Zy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Ib(t, e) {
  const n = td(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ed = (t, e) => a.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), nd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ed(o, n);
  }, ...e };
}, wb = (t) => {
  const e = nd(t);
  return i(e);
}, rd = (t, e) => a.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return rd(o, n);
  }, ...e };
}, Qb = (t) => {
  const e = sd(t);
  return i(e);
}, od = (t, e) => a.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ad = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], ud = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ad(t), queryFn: ({ signal: u }) => od(t, { signal: u, ...r }), ...n };
};
function Lb(t, e) {
  const n = ud(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const id = (t, e, n) => a.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), cd = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], yd = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? cd(t, e), queryFn: ({ signal: y }) => id(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Wb(t, e, n) {
  const r = yd(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const dd = (t, e) => a.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), pd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return dd(o, n);
  }, ...e };
}, Vb = (t) => {
  const e = pd(t);
  return i(e);
}, _d = (t, e) => a.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), md = (t) => [`/partner_dashboard/crm/event_info/${t}`], ld = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? md(t), queryFn: ({ signal: u }) => _d(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function $b(t, e) {
  const n = ld(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gd = (t) => a.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), qd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => gd(n), ...e };
}, zb = (t) => {
  const e = qd(t);
  return i(e);
}, fd = (t) => a.get(
  "/partner_dashboard/crm/sync",
  t
), Od = () => ["/partner_dashboard/crm/sync"], hd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Od(), queryFn: ({ signal: o }) => fd({ signal: o, ...n }), ...e };
};
function Hb(t) {
  const e = hd(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Kd = (t) => a.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), Pd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Kd(n), ...e };
}, Yb = (t) => {
  const e = Pd(t);
  return i(e);
}, bd = (t, e) => a.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xd = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], Md = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xd(t), queryFn: ({ signal: u }) => bd(t, { signal: u, ...r }), ...n };
};
function Xb(t, e) {
  const n = Md(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const kd = (t, e) => a.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), Fd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kd(o, n);
  }, ...e };
}, jb = (t) => {
  const e = Fd(t);
  return i(e);
}, Rd = (t, e) => a.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), Bd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Rd(o, n);
  }, ...e };
}, Zb = (t) => {
  const e = Bd(t);
  return i(e);
}, Cd = (t, e, n) => a.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), Ed = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Cd(o, u, n);
  }, ...e };
}, Jb = (t) => {
  const e = Ed(t);
  return i(e);
}, Sd = (t, e) => a.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), vd = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], Ad = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vd(t), queryFn: ({ signal: u }) => Sd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function tx(t, e) {
  const n = Ad(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Dd = (t, e) => a.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ud = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], Nd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ud(t), queryFn: ({ signal: u }) => Dd(t, { signal: u, ...r }), ...n };
};
function ex(t, e) {
  const n = Nd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gd = (t, e) => a.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), Td = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gd(o, n);
  }, ...e };
}, nx = (t) => {
  const e = Td(t);
  return i(e);
}, Id = (t) => a.get(
  "/partner_dashboard/fee_discounts/request_access",
  t
), wd = () => ["/partner_dashboard/fee_discounts/request_access"], Qd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? wd(), queryFn: ({ signal: o }) => Id({ signal: o, ...n }), ...e };
};
function rx(t) {
  const e = Qd(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ld = (t, e) => a.post(
  "/partner_dashboard/fee_discounts/request_access",
  t,
  e
), Wd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ld(o, n);
  }, ...e };
}, sx = (t) => {
  const e = Wd(t);
  return i(e);
}, Vd = (t, e) => a.get(
  "/partner_dashboard/fee_discounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $d = (t) => ["/partner_dashboard/fee_discounts", ...t ? [t] : []], zd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $d(t), queryFn: ({ signal: u }) => Vd(t, { signal: u, ...r }), ...n };
};
function ox(t, e) {
  const n = zd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hd = (t, e) => a.post(
  "/partner_dashboard/fee_discounts",
  t,
  e
), Yd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hd(o, n);
  }, ...e };
}, ax = (t) => {
  const e = Yd(t);
  return i(e);
}, Xd = (t, e) => a.get(
  `/partner_dashboard/fee_discounts/${t}`,
  e
), jd = (t) => [`/partner_dashboard/fee_discounts/${t}`], Zd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jd(t), queryFn: ({ signal: u }) => Xd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ux(t, e) {
  const n = Zd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jd = (t, e, n) => a.get(
  `/partner_dashboard/fee_discounts/${t}/businesses`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), tp = (t, e) => [`/partner_dashboard/fee_discounts/${t}/businesses`, ...e ? [e] : []], ep = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? tp(t, e), queryFn: ({ signal: y }) => Jd(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ix(t, e, n) {
  const r = ep(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const np = (t) => a.get(
  "/partner_dashboard/revenue_share",
  t
), rp = () => ["/partner_dashboard/revenue_share"], sp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? rp(), queryFn: ({ signal: o }) => np({ signal: o, ...n }), ...e };
};
function cx(t) {
  const e = sp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const op = (t, e) => a.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ap = (t) => ["/rutter_connections", ...t ? [t] : []], up = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ap(t), queryFn: ({ signal: u }) => op(t, { signal: u, ...r }), ...n };
};
function yx(t, e) {
  const n = up(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ip = (t, e) => a.post(
  "/rutter_connections",
  t,
  e
), cp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ip(o, n);
  }, ...e };
}, dx = (t) => {
  const e = cp(t);
  return i(e);
}, yp = (t, e) => a.get(
  "/rutter_connection_options",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dp = (t) => ["/rutter_connection_options", ...t ? [t] : []], pp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dp(t), queryFn: ({ signal: u }) => yp(t, { signal: u, ...r }), ...n };
};
function px(t, e) {
  const n = pp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _p = (t, e) => a.post(
  "/mark_accounting_integration_seen",
  t,
  e
), mp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return _p(o, n);
  }, ...e };
}, _x = (t) => {
  const e = mp(t);
  return i(e);
}, lp = (t) => a.get(
  "/sardine/session_id",
  t
), gp = () => ["/sardine/session_id"], qp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? gp(), queryFn: ({ signal: o }) => lp({ signal: o, ...n }), ...e };
};
function mx(t) {
  const e = qp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const fp = (t, e) => a.post(
  "/sardine/session_id",
  t,
  e
), Op = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fp(o, n);
  }, ...e };
}, lx = (t) => {
  const e = Op(t);
  return i(e);
}, hp = (t) => a.get(
  "/partner/widget_configs",
  t
), Kp = () => ["/partner/widget_configs"], Pp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Kp(), queryFn: ({ signal: o }) => hp({ signal: o, ...n }), ...e };
};
function gx(t) {
  const e = Pp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const bp = (t, e) => a.patch(
  "/partner/widget_configs",
  t,
  e
), xp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bp(o, n);
  }, ...e };
}, qx = (t) => {
  const e = xp(t);
  return i(e);
}, Mp = (t, e) => a.get(
  `/merchant_bank/applications/${t}`,
  e
), kp = (t) => [`/merchant_bank/applications/${t}`], Fp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kp(t), queryFn: ({ signal: u }) => Mp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function fx(t, e) {
  const n = Fp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rp = (t, e) => a.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bp = (t) => ["/merchant_bank/applications", ...t ? [t] : []], Cp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bp(t), queryFn: ({ signal: u }) => Rp(t, { signal: u, ...r }), ...n };
};
function Ox(t, e) {
  const n = Cp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ep = (t, e) => a.post(
  "/merchant_bank/applications",
  t,
  e
), Sp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ep(o, n);
  }, ...e };
}, hx = (t) => {
  const e = Sp(t);
  return i(e);
}, vp = (t, e) => a.post(
  "/webhooks/plaid/general",
  t,
  e
), Ap = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vp(o, n);
  }, ...e };
}, Kx = (t) => {
  const e = Ap(t);
  return i(e);
}, Dp = (t, e) => a.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Up = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Np = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Up(t), queryFn: ({ signal: u }) => Dp(t, { signal: u, ...r }), ...n };
};
function Px(t, e) {
  const n = Np(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gp = (t) => a.get(
  "/naics",
  t
), Tp = () => ["/naics"], Ip = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Tp(), queryFn: ({ signal: o }) => Gp({ signal: o, ...n }), ...e };
};
function bx(t) {
  const e = Ip(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const wp = (t, e, n, r) => a.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), Qp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return wp(o, u, y, n);
  }, ...e };
}, xx = (t) => {
  const e = Qp(t);
  return i(e);
}, Lp = (t, e, n, r) => a.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), Wp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return Lp(o, u, y, n);
  }, ...e };
}, Mx = (t) => {
  const e = Wp(t);
  return i(e);
}, Vp = (t, e) => a.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $p = (t) => ["/funding/pad_agreement", ...t ? [t] : []], zp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $p(t), queryFn: ({ signal: u }) => Vp(t, { signal: u, ...r }), ...n };
};
function kx(t, e) {
  const n = zp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hp = (t, e) => a.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), Yp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hp(o, n);
  }, ...e };
}, Fx = (t) => {
  const e = Yp(t);
  return i(e);
}, Xp = (t, e) => a.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), jp = (t) => ["/templates", ...t ? [t] : []], Zp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jp(t), queryFn: ({ signal: u }) => Xp(t, { signal: u, ...r }), ...n };
};
function Rx(t, e) {
  const n = Zp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jp = (t, e) => a.post(
  "/templates",
  t,
  e
), t_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Jp(o, n);
  }, ...e };
}, Bx = (t) => {
  const e = t_(t);
  return i(e);
}, e_ = (t, e) => a.patch(
  "/templates",
  t,
  e
), n_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return e_(o, n);
  }, ...e };
}, Cx = (t) => {
  const e = n_(t);
  return i(e);
}, r_ = (t, e) => a.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), s_ = (t) => ["/template_mock", ...t ? [t] : []], o_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? s_(t), queryFn: ({ signal: u }) => r_(t, { signal: u, ...r }), ...n };
};
function Ex(t, e) {
  const n = o_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const a_ = (t, e) => a.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), u_ = (t) => ["/template_snapshots", ...t ? [t] : []], i_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? u_(t), queryFn: ({ signal: u }) => a_(t, { signal: u, ...r }), ...n };
};
function Sx(t, e) {
  const n = i_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const c_ = (t, e) => a.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), y_ = (t) => ["/template_snapshot_urls", ...t ? [t] : []], d_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? y_(t), queryFn: ({ signal: u }) => c_(t, { signal: u, ...r }), ...n };
};
function vx(t, e) {
  const n = d_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const p_ = (t) => a.get(
  "/template_types",
  t
), __ = () => ["/template_types"], m_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? __(), queryFn: ({ signal: o }) => p_({ signal: o, ...n }), ...e };
};
function Ax(t) {
  const e = m_(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const l_ = (t, e) => a.post(
  "/template_types",
  t,
  e
), g_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return l_(o, n);
  }, ...e };
}, Dx = (t) => {
  const e = g_(t);
  return i(e);
}, q_ = (t, e) => a.post(
  "/manual_repayment",
  t,
  e
), f_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return q_(o, n);
  }, ...e };
}, Ux = (t) => {
  const e = f_(t);
  return i(e);
}, O_ = (t, e) => a.post(
  "/forgive_capital_fee",
  t,
  e
), h_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return O_(o, n);
  }, ...e };
}, Nx = (t) => {
  const e = h_(t);
  return i(e);
}, K_ = (t, e) => a.post(
  "/mfa/verify_account_details",
  t,
  e
), P_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return K_(o, n);
  }, ...e };
}, Gx = (t) => {
  const e = P_(t);
  return i(e);
}, b_ = (t) => a.patch(
  "/mfa/extend_session",
  void 0,
  t
), x_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => b_(n), ...e };
}, Tx = (t) => {
  const e = x_(t);
  return i(e);
}, M_ = (t, e) => a.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), k_ = (t) => ["/merchant_bank/offers", ...t ? [t] : []], F_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? k_(t), queryFn: ({ signal: u }) => M_(t, { signal: u, ...r }), ...n };
};
function Ix(t, e) {
  const n = F_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const R_ = (t, e) => a.post(
  "/merchant_bank/offers",
  t,
  e
), B_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return R_(o, n);
  }, ...e };
}, wx = (t) => {
  const e = B_(t);
  return i(e);
}, C_ = (t, e) => a.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), E_ = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], S_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? E_(t), queryFn: ({ signal: u }) => C_(t, { signal: u, ...r }), ...n };
};
function Qx(t, e) {
  const n = S_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const v_ = (t, e) => a.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), A_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return v_(o, n);
  }, ...e };
}, Lx = (t) => {
  const e = A_(t);
  return i(e);
}, D_ = (t, e) => a.post(
  "/auth/login/validate_otp",
  t,
  e
), U_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return D_(o, n);
  }, ...e };
}, Wx = (t) => {
  const e = U_(t);
  return i(e);
}, N_ = (t, e) => a.post(
  "/auth/login/start",
  t,
  e
), G_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return N_(o, n);
  }, ...e };
}, Vx = (t) => {
  const e = G_(t);
  return i(e);
}, T_ = (t, e) => a.post(
  "/auth/token/exchange",
  t,
  e
), I_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return T_(o, n);
  }, ...e };
}, $x = (t) => {
  const e = I_(t);
  return i(e);
}, w_ = (t, e) => a.get(
  `/auth/management/${t}/saml`,
  e
), Q_ = (t) => [`/auth/management/${t}/saml`], L_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q_(t), queryFn: ({ signal: u }) => w_(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function zx(t, e) {
  const n = L_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const W_ = (t, e, n) => a.post(
  `/auth/management/${t}/saml`,
  e,
  n
), V_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, data: u } = s ?? {};
    return W_(o, u, n);
  }, ...e };
}, Hx = (t) => {
  const e = V_(t);
  return i(e);
}, $_ = (t, e, n) => a.delete(
  `/auth/management/${t}/saml/${e}`,
  n
), z_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u } = s ?? {};
    return $_(o, u, n);
  }, ...e };
}, Yx = (t) => {
  const e = z_(t);
  return i(e);
}, H_ = (t, e, n, r) => a.patch(
  `/auth/management/${t}/saml/${e}`,
  n,
  r
), Y_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u, data: y } = s ?? {};
    return H_(o, u, y, n);
  }, ...e };
}, Xx = (t) => {
  const e = Y_(t);
  return i(e);
}, X_ = (t, e) => a.post(
  "/auth/email_otp/verify",
  t,
  e
), j_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return X_(o, n);
  }, ...e };
}, jx = (t) => {
  const e = j_(t);
  return i(e);
}, Z_ = (t, e) => a.post(
  "/auth/email_otp/v2/verify",
  t,
  e
), J_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Z_(o, n);
  }, ...e };
}, Zx = (t) => {
  const e = J_(t);
  return i(e);
}, tm = (t, e) => a.post(
  "/auth/email_otp/start",
  t,
  e
), em = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return tm(o, n);
  }, ...e };
}, Jx = (t) => {
  const e = em(t);
  return i(e);
}, nm = (t, e) => a.get(
  `/introductory_offer/underwrite/${t}`,
  e
), rm = (t) => [`/introductory_offer/underwrite/${t}`], sm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rm(t), queryFn: ({ signal: u }) => nm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function tM(t, e) {
  const n = sm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const om = (t, e) => a.post(
  "/introductory_offer/underwrite",
  t,
  e
), am = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return om(o, n);
  }, ...e };
}, eM = (t) => {
  const e = am(t);
  return i(e);
}, um = (t, e) => a.get(
  "/card/balances",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), im = (t) => ["/card/balances", ...t ? [t] : []], cm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? im(t), queryFn: ({ signal: u }) => um(t, { signal: u, ...r }), ...n };
};
function nM(t, e) {
  const n = cm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ym = (t) => a.get(
  "/cardholders",
  t
), dm = () => ["/cardholders"], pm = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? dm(), queryFn: ({ signal: o }) => ym({ signal: o, ...n }), ...e };
};
function rM(t) {
  const e = pm(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const _m = (t, e, n) => a.patch(
  `/cardholders/${t}`,
  e,
  n
), mm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { cardholderId: o, data: u } = s ?? {};
    return _m(o, u, n);
  }, ...e };
}, sM = (t) => {
  const e = mm(t);
  return i(e);
}, lm = (t, e) => a.get(
  "/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gm = (t) => ["/card/access_token", ...t ? [t] : []], qm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gm(t), queryFn: ({ signal: u }) => lm(t, { signal: u, ...r }), ...n };
};
function oM(t, e) {
  const n = qm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fm = (t, e) => a.get(
  "/card/lifecycle_states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Om = (t) => ["/card/lifecycle_states", ...t ? [t] : []], hm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Om(t), queryFn: ({ signal: u }) => fm(t, { signal: u, ...r }), ...n };
};
function aM(t, e) {
  const n = hm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Km = (t, e) => a.get(
  "/card/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pm = (t) => ["/card/transactions", ...t ? [t] : []], bm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pm(t), queryFn: ({ signal: u }) => Km(t, { signal: u, ...r }), ...n };
};
function uM(t, e) {
  const n = bm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xm = (t, e) => a.get(
  "/card/pending_transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mm = (t) => ["/card/pending_transactions", ...t ? [t] : []], km = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mm(t), queryFn: ({ signal: u }) => xm(t, { signal: u, ...r }), ...n };
};
function iM(t, e) {
  const n = km(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fm = (t, e) => a.get(
  `/orders/${t}`,
  e
), Rm = (t) => [`/orders/${t}`], Bm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rm(t), queryFn: ({ signal: u }) => Fm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function cM(t, e) {
  const n = Bm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cm = (t, e) => a.get(
  `/refund/${t}`,
  e
), Em = (t) => [`/refund/${t}`], Sm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Em(t), queryFn: ({ signal: u }) => Cm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function yM(t, e) {
  const n = Sm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vm = (t, e) => a.get(
  "/opt_ins",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Am = (t) => ["/opt_ins", ...t ? [t] : []], Dm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Am(t), queryFn: ({ signal: u }) => vm(t, { signal: u, ...r }), ...n };
};
function dM(t, e) {
  const n = Dm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Um = (t, e) => a.post(
  "/opt_ins",
  t,
  e
), Nm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Um(o, n);
  }, ...e };
}, pM = (t) => {
  const e = Nm(t);
  return i(e);
}, Gm = (t, e) => a.post(
  "/bulk_opt_in",
  t,
  e
), Tm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gm(o, n);
  }, ...e };
}, _M = (t) => {
  const e = Tm(t);
  return i(e);
}, Im = (t, e) => a.get(
  "/opt_out_eligibility",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wm = (t) => ["/opt_out_eligibility", ...t ? [t] : []], Qm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wm(t), queryFn: ({ signal: u }) => Im(t, { signal: u, ...r }), ...n };
};
function mM(t, e) {
  const n = Qm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lm = (t, e) => a.post(
  "/opt_out",
  t,
  e
), Wm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Lm(o, n);
  }, ...e };
}, lM = (t) => {
  const e = Wm(t);
  return i(e);
}, Vm = (t, e) => a.put(
  "/sandbox/capital_product_test_offer",
  t,
  e
), $m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Vm(o, n);
  }, ...e };
}, gM = (t) => {
  const e = $m(t);
  return i(e);
}, zm = (t, e) => a.patch(
  "/sandbox/capital_product_fund",
  t,
  e
), Hm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return zm(o, n);
  }, ...e };
}, qM = (t) => {
  const e = Hm(t);
  return i(e);
}, Ym = (t, e) => a.patch(
  "/sandbox/capital_product_funding_checklist",
  t,
  e
), Xm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ym(o, n);
  }, ...e };
}, fM = (t) => {
  const e = Xm(t);
  return i(e);
}, jm = (t, e) => a.patch(
  "/sandbox/capital_product_top_up",
  t,
  e
), Zm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jm(o, n);
  }, ...e };
}, OM = (t) => {
  const e = Zm(t);
  return i(e);
}, Jm = (t, e) => a.post(
  "/sandbox/capital_product_pay_off",
  t,
  e
), tl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Jm(o, n);
  }, ...e };
}, hM = (t) => {
  const e = tl(t);
  return i(e);
}, el = (t, e) => a.post(
  "/sandbox/amazon_lock_bank_account",
  t,
  e
), nl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return el(o, n);
  }, ...e };
}, KM = (t) => {
  const e = nl(t);
  return i(e);
}, rl = (t, e) => a.post(
  "/sandbox/break_bank_account",
  t,
  e
), sl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return rl(o, n);
  }, ...e };
}, PM = (t) => {
  const e = sl(t);
  return i(e);
}, ol = (t, e) => a.post(
  "/sandbox/repayments",
  t,
  e
), al = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ol(o, n);
  }, ...e };
}, bM = (t) => {
  const e = al(t);
  return i(e);
}, ul = (t, e) => a.patch(
  "/sandbox/repayments",
  t,
  e
), il = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ul(o, n);
  }, ...e };
}, xM = (t) => {
  const e = il(t);
  return i(e);
}, cl = (t, e) => a.post(
  "/sandbox/merchant_bank/setup_business",
  t,
  e
), yl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return cl(o, n);
  }, ...e };
}, MM = (t) => {
  const e = yl(t);
  return i(e);
}, dl = (t, e) => a.put(
  "/sandbox/fee_discount",
  t,
  e
), pl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return dl(o, n);
  }, ...e };
}, kM = (t) => {
  const e = pl(t);
  return i(e);
}, _l = (t, e) => a.get(
  "/repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ml = (t) => ["/repayments", ...t ? [t] : []], ll = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ml(t), queryFn: ({ signal: u }) => _l(t, { signal: u, ...r }), ...n };
};
function FM(t, e) {
  const n = ll(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gl = (t, e) => a.get(
  "/businesses/show_top_up_offer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ql = (t) => ["/businesses/show_top_up_offer", ...t ? [t] : []], fl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ql(t), queryFn: ({ signal: u }) => gl(t, { signal: u, ...r }), ...n };
};
function RM(t, e) {
  const n = fl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ol = (t, e) => a.get(
  "/funding/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hl = (t) => ["/funding/plaid_link_token", ...t ? [t] : []], Kl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hl(t), queryFn: ({ signal: u }) => Ol(t, { signal: u, ...r }), ...n };
};
function BM(t, e) {
  const n = Kl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pl = (t, e) => a.get(
  "/statements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bl = (t) => ["/statements", ...t ? [t] : []], xl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bl(t), queryFn: ({ signal: u }) => Pl(t, { signal: u, ...r }), ...n };
};
function CM(t, e) {
  const n = xl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ml = (t, e) => a.get(
  "/early_manual_repayment_whitelist",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), kl = (t) => ["/early_manual_repayment_whitelist", ...t ? [t] : []], Fl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kl(t), queryFn: ({ signal: u }) => Ml(t, { signal: u, ...r }), ...n };
};
function EM(t, e) {
  const n = Fl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rl = (t, e) => a.patch(
  "/",
  t,
  e
), Bl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Rl(o, n);
  }, ...e };
}, SM = (t) => {
  const e = Bl(t);
  return i(e);
}, Cl = (t, e) => a.get(
  "/bnpl/underwrite",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), El = (t) => ["/bnpl/underwrite", ...t ? [t] : []], Sl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? El(t), queryFn: ({ signal: u }) => Cl(t, { signal: u, ...r }), ...n };
};
function vM(t, e) {
  const n = Sl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vl = (t, e) => a.post(
  "/bnpl/underwrite",
  t,
  e
), Al = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vl(o, n);
  }, ...e };
}, AM = (t) => {
  const e = Al(t);
  return i(e);
}, Dl = (t, e) => a.get(
  "/net_settlement_opt_in",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ul = (t) => ["/net_settlement_opt_in", ...t ? [t] : []], Nl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ul(t), queryFn: ({ signal: u }) => Dl(t, { signal: u, ...r }), ...n };
};
function DM(t, e) {
  const n = Nl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gl = (t, e) => a.post(
  "/net_settlement_opt_in",
  t,
  e
), Tl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gl(o, n);
  }, ...e };
}, UM = (t) => {
  const e = Tl(t);
  return i(e);
}, Il = (t, e) => a.get(
  "/funding/bank_linking_error",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wl = (t) => ["/funding/bank_linking_error", ...t ? [t] : []], Ql = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wl(t), queryFn: ({ signal: u }) => Il(t, { signal: u, ...r }), ...n };
};
function NM(t, e) {
  const n = Ql(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ll = (t, e) => a.get(
  `/business_dashboard/applications/${t}`,
  e
), Wl = (t) => [`/business_dashboard/applications/${t}`], Vl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wl(t), queryFn: ({ signal: u }) => Ll(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function GM(t, e) {
  const n = Vl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $l = (t, e) => a.get(
  "/business_dashboard/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zl = (t) => ["/business_dashboard/applications", ...t ? [t] : []], Hl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zl(t), queryFn: ({ signal: u }) => $l(t, { signal: u, ...r }), ...n };
};
function TM(t, e) {
  const n = Hl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const IM = (t) => i({
  mutationFn: (e) => a.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), wM = (t) => i({
  mutationFn: ({
    id: e,
    ...n
  }) => a.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), QM = (t) => i({
  mutationFn: (e) => a.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), LM = (t) => i({
  mutationFn: (e) => a.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), WM = (t) => i({
  mutationFn: (e) => a.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), VM = (t) => i({
  mutationFn: (e) => a.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), $M = (t) => i({
  mutationFn: (e) => a.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), zM = (t) => i({
  mutationFn: (e) => a.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), HM = (t) => i({
  mutationFn: (e) => a.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), YM = (t) => i({
  mutationFn: (e) => a.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), XM = (t) => i({
  mutationFn: (e) => a.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), jM = (t) => i({
  mutationFn: (e) => a.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), ZM = (t) => i({
  mutationFn: (e) => a.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), JM = (t) => i({
  mutationFn: (e) => a.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), tk = (t) => i({
  mutationFn: (e) => a.post("/tickets", e, t == null ? void 0 : t.axios)
}), ek = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/sandbox/bank_account/{id}/verify",
    method: "post",
    name: "Verify Bank Account (Sandbox)"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests/initiate",
    method: "patch",
    name: "Initiate Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/orders/{id}",
    method: "get",
    name: "Get Order"
  },
  {
    path: "/v1/orders",
    method: "get",
    name: "List Orders"
  },
  {
    path: "/v1/orders",
    method: "post",
    name: "Create Order"
  },
  {
    path: "/v1/orders/{id}/cancel",
    method: "post",
    name: "Cancel Order"
  },
  {
    path: "/v1/orders/{id}/finalize",
    method: "post",
    name: "Finalize Order"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/refunds",
    method: "get",
    name: "List Refunds"
  },
  {
    path: "/v1/refunds",
    method: "post",
    name: "Create Refund"
  },
  {
    path: "/v1/refunds/{id}",
    method: "get",
    name: "Get Refund"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/spend_save/balance",
    method: "post",
    name: "Add Business Spend Save Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  },
  {
    path: "/v1/payments",
    method: "get",
    name: "List Payments "
  },
  {
    path: "/v1/payments",
    method: "post",
    name: "Create Payment"
  },
  {
    path: "/v1/payments/{id}",
    method: "get",
    name: "Get Payment"
  },
  {
    path: "/v1/payments/batch_create",
    method: "post",
    name: "Batch Create Payment"
  }
];
export {
  iO as AccountOwner,
  uO as AddressType,
  aO as AdverseActionReason,
  oO as ApplicationAbandonedReason,
  sO as BankAccountInfoDataShare,
  rO as BankAccountStatus,
  nO as BusinessProductType,
  eO as CapitalProductActivityType,
  tO as CapitalProductApplicationStatus,
  Jf as CapitalProductApplicationStatus1,
  Zf as CapitalProductApplicationTransitionReason,
  jf as CapitalProductApplicationType,
  Xf as CapitalProductHealthStatus,
  Yf as CapitalProductInternalRepaymentPlanType,
  Hf as CapitalProductOfferLabel,
  zf as CapitalProductState,
  $f as CapitalProductSuperApplicationActionType,
  Vf as CapitalProductSuperApplicationStatus,
  Wf as CapitalProductType,
  Lf as CapitalProductType1,
  Qf as CardBusinessTableRowStatus,
  wf as CardDeserializerCardType,
  If as CardLifecycleStateSerializerLifecycleState,
  Uf as CardSerializer1FulfillmentStatus,
  Df as CardSerializer1State,
  Tf as CardSerializerCardType,
  Gf as CardSerializerFulfillmentStatus,
  Nf as CardSerializerState,
  Af as CardShippingMethod,
  vf as CardTransactionType,
  Sf as CardTransitionChannel,
  Ef as CardTransitionReasonCode,
  Cf as CardTransitionState,
  Bf as CardTransitionType,
  Rf as CountryCode,
  Ff as CrmDeliveryAttemptState,
  kf as CrmEventType,
  Mf as CrmVendor,
  xf as Currency,
  bf as Currency1,
  Pf as CurrencyCode,
  Kf as DataRequirement,
  hf as DatasetType,
  Of as DatasetUploadStatus,
  ff as DatashareMethod,
  qf as DayOfWeek,
  gf as DecisionStatus,
  lf as DeniedReason,
  mf as Direction,
  _f as DiscountProgramFundingType,
  pf as DiscountProgramState,
  df as DiscountProgramType,
  yf as DisputeState,
  cf as DocumentUploadPurpose,
  uf as DoordashAdminUserMxRole,
  af as EditSource,
  of as EmailBatchState,
  sf as EmailSubscriptionState,
  rf as EmailTriggerSource,
  nf as EntityType,
  ef as FeeDiscountProgramAccessRequestState,
  tf as FieldType,
  Jq as FinancialRecordDocType,
  Zq as FutureActivityType,
  jq as IdentityDataShare,
  Xq as IncorporationJurisdiction,
  Yq as IncorporationJurisdiction1,
  Hq as IncorporationType,
  zq as IncorporationType1,
  $q as InfoNeededStatus,
  Vq as IngestionStatus,
  Wq as InnerTransferType,
  Lq as InvalidInputReason,
  Qq as KycApplicationStatus,
  wq as KycBusinessReportFieldType,
  Iq as KycIndividualReportFieldType,
  Tq as LienReviewState,
  Gq as LienStatus,
  Nq as LienType,
  Uq as LoanBusinessTableRowStatus,
  Dq as ManualReviewReason,
  Aq as MarketingEmailType,
  vq as McaBusinessTableRowStatus,
  Sq as MerchantBankAccountType,
  Eq as MerchantBankApplicationState,
  Bq as MerchantBankCardDisputeReason,
  Cq as MerchantBankCardDisputeState,
  Rq as MerchantBankCardPaymentPreference,
  Fq as MerchantBankCardReplacementReason,
  kq as MerchantBankCardType,
  Mq as MerchantBankExternalAccountState,
  xq as MerchantBankExternalBankAccountType,
  bq as MerchantBankOfferProductType,
  Pq as MerchantBankReceivedTransferType,
  Kq as MerchantBankRecipientPaymentType,
  hq as MerchantBankRepaymentType,
  Oq as MerchantBankTransactionDestinationAccountType,
  fq as MerchantBankTransactionPaymentDirection,
  qq as MerchantBankTransactionState,
  gq as MerchantBankTransactionType,
  lq as MerchantBankTransferPaymentType,
  mq as MicroDepositVerificationState,
  _q as MinimumRepaymentAmountDueReason,
  pq as NetSettlementOptInState,
  dq as NetSettlementState,
  yq as NotificationType,
  cq as OnboardingAction,
  iq as OnboardingProductType,
  uq as OnboardingState,
  aq as OneOffActionType,
  oq as OptInState,
  sq as OrderStatus,
  rq as OrderStatus1,
  nq as ParafinProduct,
  eq as PartnerEventType,
  tq as PdUserRole,
  Jg as PersonaInquiryType,
  Zg as PhoneNumberCountryCode,
  jg as PhoneNumberType,
  Xg as PlaidInvestigationDeniedReason,
  Yg as PlaidLinkageWithInvestigationSerializerState,
  Hg as RefundFailureReason,
  zg as RefundStatus,
  $g as RepaymentDetailsRepaymentPlanType,
  Vg as RepaymentDisbursementType,
  Wg as RepaymentMethodType,
  Lg as RepaymentPauseSerializerPauseType,
  Qg as RepaymentSchedule,
  wg as RepaymentSerializer1RepaymentType,
  Ig as RepaymentSerializer1State,
  Tg as RepaymentsPatchDeserializerState,
  Gg as RepaymentsPostDeserializerRepaymentType,
  Ng as RepaymentsPostDeserializerState,
  Ug as ReplacementDetailsReplacementReason,
  Dg as RestrictionExternalReason,
  Ag as RestrictionReason,
  vg as RestrictionSource,
  Sg as RestrictionType,
  Eg as ReturnCode,
  Cg as RevenueShareAccountType,
  Bg as RevenueShareBasisType,
  Rg as ReversalReason,
  Fg as RutterConnectionState,
  kg as RutterIntegrationType,
  Mg as RutterPlatform,
  xg as S3PartnerDashboardCsvStatus,
  bg as S3PartnerDashboardCsvTableType,
  Pg as SalesDataShare,
  Kg as SardineCustomerScoreState,
  hg as SardineFlowName,
  Og as SardineLevel,
  fg as Schedule,
  qg as SessionType,
  gg as State,
  lg as StripeSaleRecordIngestionType,
  mg as TermsOfServiceLinkType,
  _g as Timezone,
  pg as UnderwriteIntroductoryOfferAttemptFailureReason,
  dg as UnderwriteIntroductoryOfferAttemptStatus,
  yg as UnderwritingDesiredOutputState,
  cg as UnifiedRealtimeUnderwritingAttemptFailureReason,
  ig as UnifiedRealtimeUnderwritingAttemptStatus,
  ug as UserRole,
  ag as ValidationStatus,
  og as VerificationStatus,
  sg as WebNotificationState,
  rg as WebSubscriptionState,
  ng as WebhookDeliveryAttemptState,
  eg as WebhookEndpointStatus,
  tg as WebhookEventDeliveryState,
  Jl as WebhookEventMode,
  Zl as WebhookEventType,
  gd as attemptSync,
  Py as batchCreatePdPartnerEventMarkAllNotificationsRead,
  rd as batchResendWebhookAttempt,
  Er as benchmarkGetCapitalProductOwnership,
  Ac as countPdBusinessTableRows,
  Zc as countPdCapitalProductApplicationRow,
  sy as countPdCapitalProductOfferRow,
  Ic as countPdCapitalProductRow,
  $c as countPdOrderRow,
  vl as createAttemptToUnderwriteBnplOrder,
  om as createAttemptToUnderwriteIntroductoryOffers,
  fs as createBankAccountInfo,
  Qr as createCapitalProductIntents,
  jr as createCapitalProductSuperApplicationActions,
  Yr as createCapitalProductSuperApplications,
  rr as createCapitalProducts,
  Nr as createOfferedCapitalProductFeeDiscount,
  yt as createOrganization,
  ot as createPartnerApiKey,
  Hd as createPdFeeDiscountProgram,
  Ld as createPdFeeDiscountProgramAccessRequest,
  xy as createPdPartnerEventMarkNotificationRead,
  kd as createPdWebhookEndpoint,
  Cs as createPlaidInvestigation,
  Vi as createS3OfferCsv,
  Gd as createS3PartnerDashboardTableCsv,
  $_ as deleteAuthManagementUseridSamlSamlconfigid,
  Fe as deleteBeneficialOwnersId,
  nn as deleteBusinessOfficersId,
  U as deleteCashAdvancePausesP1,
  me as deleteMarketingIntegrationsVendor,
  Ju as deleteMerchantBankExternalAccountsId,
  Xa as deleteMerchantBankRecipientsId,
  vt as deleteOrganizationDeleteUsers,
  Et as deletePartnerDeleteUsers,
  Zt as deletePartnerSyncWebhooksId,
  Rd as deletePdWebhookEndpoint,
  Pt as deleteSlackChannelsId,
  wy as experimentalCapitalProductOfferClosedSandbox,
  Ty as experimentalCapitalProductOfferCreatedSandbox,
  xi as getAccountingInfo,
  Dp as getAmazonFundingAccountLock,
  Ui as getAnalyticsCubeToken,
  Ll as getApplication,
  qd as getAttemptSyncMutationOptions,
  w_ as getAuthManagementP1Saml,
  ls as getBankAccountInfos,
  Ue as getBanks,
  by as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  sd as getBatchResendWebhookAttemptMutationOptions,
  Sr as getBenchmarkGetCapitalProductOwnershipQueryKey,
  vr as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  Pe as getBeneficialOwners,
  b as getBusinessAnnotations,
  f as getBusinessCores,
  Vs as getBusinessIdentities,
  je as getBusinessOfficers,
  He as getBusinessTableRowsCard,
  Ve as getBusinessTableRowsLoan,
  Qe as getBusinessTableRowsMca,
  Te as getBusinessTableRowsProductAgnostic,
  Jo as getBusinessesDailySalesRecords,
  rc as getBusinessesNotifications,
  Mo as getBusinessesProducts,
  gl as getBusinessesShowTopUpOffer,
  Jr as getCalculatedChunkValues,
  pr as getCapitalProductActivities,
  hr as getCapitalProductApplicationSnapshots,
  lr as getCapitalProductApplications,
  Tr as getCapitalProductIntents,
  Mr as getCapitalProductNetTopUpItemization,
  ur as getCapitalProductOfferCollection,
  jn as getCapitalProductProspectiveTermsOfService,
  Ar as getCapitalProductRepaymentSchedule,
  Rr as getCapitalProductStateDisclosure,
  tr as getCapitalProducts,
  lm as getCardAccessToken,
  um as getCardBalances,
  fm as getCardLifecycleStates,
  xm as getCardPendingTransactions,
  Km as getCardTransactions,
  ym as getCardholders,
  ho as getCards,
  E as getCashAdvancePauses,
  Dc as getCountPdBusinessTableRowsQueryKey,
  Uc as getCountPdBusinessTableRowsQueryOptions,
  Jc as getCountPdCapitalProductApplicationRowQueryKey,
  ty as getCountPdCapitalProductApplicationRowQueryOptions,
  oy as getCountPdCapitalProductOfferRowQueryKey,
  ay as getCountPdCapitalProductOfferRowQueryOptions,
  wc as getCountPdCapitalProductRowQueryKey,
  Qc as getCountPdCapitalProductRowQueryOptions,
  zc as getCountPdOrderRowQueryKey,
  Hc as getCountPdOrderRowQueryOptions,
  $r as getCounteroffer,
  Al as getCreateAttemptToUnderwriteBnplOrderMutationOptions,
  am as getCreateAttemptToUnderwriteIntroductoryOffersMutationOptions,
  Os as getCreateBankAccountInfoMutationOptions,
  Lr as getCreateCapitalProductIntentsMutationOptions,
  Zr as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  Xr as getCreateCapitalProductSuperApplicationsMutationOptions,
  sr as getCreateCapitalProductsMutationOptions,
  Gr as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  dt as getCreateOrganizationMutationOptions,
  at as getCreatePartnerApiKeyMutationOptions,
  Wd as getCreatePdFeeDiscountProgramAccessRequestMutationOptions,
  Yd as getCreatePdFeeDiscountProgramMutationOptions,
  My as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  Fd as getCreatePdWebhookEndpointMutationOptions,
  Es as getCreatePlaidInvestigationMutationOptions,
  $i as getCreateS3OfferCsvMutationOptions,
  Td as getCreateS3PartnerDashboardTableCsvMutationOptions,
  En as getDataingestionS3DatasetIngestionConfigs,
  Kn as getDataingestionS3DatasetIntegrations,
  fn as getDataingestionS3DatasetUploadValidationResults,
  ln as getDataingestionS3DatasetUploads,
  kn as getDataingestionS3OrgDatasetIntegrations,
  wn as getDataingestionStripeIngestions,
  mi as getDebtChecks,
  ui as getDebts,
  z_ as getDeleteAuthManagementUseridSamlSamlconfigidMutationOptions,
  Re as getDeleteBeneficialOwnersIdMutationOptions,
  rn as getDeleteBusinessOfficersIdMutationOptions,
  N as getDeleteCashAdvancePausesP1MutationOptions,
  le as getDeleteMarketingIntegrationsVendorMutationOptions,
  ti as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  ja as getDeleteMerchantBankRecipientsIdMutationOptions,
  At as getDeleteOrganizationDeleteUsersMutationOptions,
  St as getDeletePartnerDeleteUsersMutationOptions,
  Jt as getDeletePartnerSyncWebhooksIdMutationOptions,
  Bd as getDeletePdWebhookEndpointMutationOptions,
  bt as getDeleteSlackChannelsIdMutationOptions,
  Co as getDocumentUploadGroup,
  Do as getDocumentUploadList,
  Ml as getEarlyManualRepaymentWhitelist,
  Qy as getExperimentalCapitalProductOfferClosedSandboxMutationOptions,
  Iy as getExperimentalCapitalProductOfferCreatedSandboxMutationOptions,
  Oi as getFinancialReviews,
  ns as getFlexLoanMinimumRepaymentSchedule,
  Ps as getFundingAccounts,
  Ol as getFundingPlaidLinkToken,
  L as getFutureActivities,
  Mi as getGetAccountingInfoQueryKey,
  ki as getGetAccountingInfoQueryOptions,
  Up as getGetAmazonFundingAccountLockQueryKey,
  Np as getGetAmazonFundingAccountLockQueryOptions,
  Ni as getGetAnalyticsCubeTokenQueryKey,
  Gi as getGetAnalyticsCubeTokenQueryOptions,
  Wl as getGetApplicationQueryKey,
  Vl as getGetApplicationQueryOptions,
  Q_ as getGetAuthManagementP1SamlQueryKey,
  L_ as getGetAuthManagementP1SamlQueryOptions,
  gs as getGetBankAccountInfosQueryKey,
  qs as getGetBankAccountInfosQueryOptions,
  Ne as getGetBanksQueryKey,
  Ge as getGetBanksQueryOptions,
  be as getGetBeneficialOwnersQueryKey,
  xe as getGetBeneficialOwnersQueryOptions,
  x as getGetBusinessAnnotationsQueryKey,
  M as getGetBusinessAnnotationsQueryOptions,
  O as getGetBusinessCoresQueryKey,
  h as getGetBusinessCoresQueryOptions,
  $s as getGetBusinessIdentitiesQueryKey,
  zs as getGetBusinessIdentitiesQueryOptions,
  Ze as getGetBusinessOfficersQueryKey,
  Je as getGetBusinessOfficersQueryOptions,
  Ye as getGetBusinessTableRowsCardQueryKey,
  Xe as getGetBusinessTableRowsCardQueryOptions,
  $e as getGetBusinessTableRowsLoanQueryKey,
  ze as getGetBusinessTableRowsLoanQueryOptions,
  Le as getGetBusinessTableRowsMcaQueryKey,
  We as getGetBusinessTableRowsMcaQueryOptions,
  Ie as getGetBusinessTableRowsProductAgnosticQueryKey,
  we as getGetBusinessTableRowsProductAgnosticQueryOptions,
  ta as getGetBusinessesDailySalesRecordsQueryKey,
  ea as getGetBusinessesDailySalesRecordsQueryOptions,
  sc as getGetBusinessesNotificationsQueryKey,
  oc as getGetBusinessesNotificationsQueryOptions,
  ko as getGetBusinessesProductsQueryKey,
  Fo as getGetBusinessesProductsQueryOptions,
  ql as getGetBusinessesShowTopUpOfferQueryKey,
  fl as getGetBusinessesShowTopUpOfferQueryOptions,
  ts as getGetCalculatedChunkValuesQueryKey,
  es as getGetCalculatedChunkValuesQueryOptions,
  _r as getGetCapitalProductActivitiesQueryKey,
  mr as getGetCapitalProductActivitiesQueryOptions,
  Kr as getGetCapitalProductApplicationSnapshotsQueryKey,
  Pr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  gr as getGetCapitalProductApplicationsQueryKey,
  qr as getGetCapitalProductApplicationsQueryOptions,
  Ir as getGetCapitalProductIntentsQueryKey,
  wr as getGetCapitalProductIntentsQueryOptions,
  kr as getGetCapitalProductNetTopUpItemizationQueryKey,
  Fr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  ir as getGetCapitalProductOfferCollectionQueryKey,
  cr as getGetCapitalProductOfferCollectionQueryOptions,
  Zn as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  Jn as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Dr as getGetCapitalProductRepaymentScheduleQueryKey,
  Ur as getGetCapitalProductRepaymentScheduleQueryOptions,
  Br as getGetCapitalProductStateDisclosureQueryKey,
  Cr as getGetCapitalProductStateDisclosureQueryOptions,
  er as getGetCapitalProductsQueryKey,
  nr as getGetCapitalProductsQueryOptions,
  gm as getGetCardAccessTokenQueryKey,
  qm as getGetCardAccessTokenQueryOptions,
  im as getGetCardBalancesQueryKey,
  cm as getGetCardBalancesQueryOptions,
  Om as getGetCardLifecycleStatesQueryKey,
  hm as getGetCardLifecycleStatesQueryOptions,
  Mm as getGetCardPendingTransactionsQueryKey,
  km as getGetCardPendingTransactionsQueryOptions,
  Pm as getGetCardTransactionsQueryKey,
  bm as getGetCardTransactionsQueryOptions,
  dm as getGetCardholdersQueryKey,
  pm as getGetCardholdersQueryOptions,
  Ko as getGetCardsQueryKey,
  Po as getGetCardsQueryOptions,
  S as getGetCashAdvancePausesQueryKey,
  v as getGetCashAdvancePausesQueryOptions,
  zr as getGetCounterofferQueryKey,
  Hr as getGetCounterofferQueryOptions,
  Sn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  vn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  Pn as getGetDataingestionS3DatasetIntegrationsQueryKey,
  bn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  On as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  hn as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  gn as getGetDataingestionS3DatasetUploadsQueryKey,
  qn as getGetDataingestionS3DatasetUploadsQueryOptions,
  Fn as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  Rn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  Qn as getGetDataingestionStripeIngestionsQueryKey,
  Ln as getGetDataingestionStripeIngestionsQueryOptions,
  li as getGetDebtChecksQueryKey,
  gi as getGetDebtChecksQueryOptions,
  ii as getGetDebtsQueryKey,
  ci as getGetDebtsQueryOptions,
  Eo as getGetDocumentUploadGroupQueryKey,
  So as getGetDocumentUploadGroupQueryOptions,
  Uo as getGetDocumentUploadListQueryKey,
  No as getGetDocumentUploadListQueryOptions,
  kl as getGetEarlyManualRepaymentWhitelistQueryKey,
  Fl as getGetEarlyManualRepaymentWhitelistQueryOptions,
  hi as getGetFinancialReviewsQueryKey,
  Ki as getGetFinancialReviewsQueryOptions,
  rs as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  ss as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  bs as getGetFundingAccountsQueryKey,
  xs as getGetFundingAccountsQueryOptions,
  hl as getGetFundingPlaidLinkTokenQueryKey,
  Kl as getGetFundingPlaidLinkTokenQueryOptions,
  W as getGetFutureActivitiesQueryKey,
  V as getGetFutureActivitiesQueryOptions,
  Ds as getGetKycRetryFieldsQueryKey,
  Us as getGetKycRetryFieldsQueryOptions,
  rm as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryKey,
  sm as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryOptions,
  zo as getGetLiensQueryKey,
  Ho as getGetLiensQueryOptions,
  qe as getGetMarketingGtmToolkitQueryKey,
  fe as getGetMarketingGtmToolkitQueryOptions,
  ye as getGetMarketingIntegrationsQueryKey,
  de as getGetMarketingIntegrationsQueryOptions,
  he as getGetMarketingParagonTokenQueryKey,
  Ke as getGetMarketingParagonTokenQueryOptions,
  fa as getGetMerchantBankAccountsIdBalancesQueryKey,
  Oa as getGetMerchantBankAccountsIdBalancesQueryOptions,
  la as getGetMerchantBankAccountsIdQueryKey,
  ga as getGetMerchantBankAccountsIdQueryOptions,
  Ka as getGetMerchantBankAccountsQueryKey,
  Pa as getGetMerchantBankAccountsQueryOptions,
  kp as getGetMerchantBankApplicationsIdQueryKey,
  Fp as getGetMerchantBankApplicationsIdQueryOptions,
  Bp as getGetMerchantBankApplicationsQueryKey,
  Cp as getGetMerchantBankApplicationsQueryOptions,
  Ou as getGetMerchantBankCardAccessTokenQueryKey,
  hu as getGetMerchantBankCardAccessTokenQueryOptions,
  xu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  Mu as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  mu as getGetMerchantBankCardCardholdersQueryKey,
  lu as getGetMerchantBankCardCardholdersQueryOptions,
  Eu as getGetMerchantBankCardDisputesQueryKey,
  Su as getGetMerchantBankCardDisputesQueryOptions,
  uu as getGetMerchantBankCardsQueryKey,
  iu as getGetMerchantBankCardsQueryOptions,
  Lu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  Wu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  ju as getGetMerchantBankExternalAccountsIdQueryKey,
  Zu as getGetMerchantBankExternalAccountsIdQueryOptions,
  Iu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  wu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  $u as getGetMerchantBankExternalAccountsQueryKey,
  zu as getGetMerchantBankExternalAccountsQueryOptions,
  Ia as getGetMerchantBankInterestQueryKey,
  wa as getGetMerchantBankInterestQueryOptions,
  k_ as getGetMerchantBankOffersQueryKey,
  F_ as getGetMerchantBankOffersQueryOptions,
  Ha as getGetMerchantBankRecipientsIdQueryKey,
  Ya as getGetMerchantBankRecipientsIdQueryOptions,
  La as getGetMerchantBankRecipientsQueryKey,
  Wa as getGetMerchantBankRecipientsQueryOptions,
  Ra as getGetMerchantBankTransactionsIdQueryKey,
  Ba as getGetMerchantBankTransactionsIdQueryOptions,
  Ea as getGetMerchantBankTransactionsQueryKey,
  Sa as getGetMerchantBankTransactionsQueryOptions,
  Aa as getGetMerchantBankTransferFeesQueryKey,
  Da as getGetMerchantBankTransferFeesQueryOptions,
  Na as getGetMerchantBankTransferLimitsQueryKey,
  Ga as getGetMerchantBankTransferLimitsQueryOptions,
  Tp as getGetNaicsQueryKey,
  Ip as getGetNaicsQueryOptions,
  Ul as getGetNetSettlementsOptInStatusQueryKey,
  Nl as getGetNetSettlementsOptInStatusQueryOptions,
  Si as getGetOfficerSubsidiaryRecordsQueryKey,
  vi as getGetOfficerSubsidiaryRecordsQueryOptions,
  Yn as getGetOnboardingBusinessRequirementsQueryKey,
  Xn as getGetOnboardingBusinessRequirementsQueryOptions,
  Ls as getGetOnboardingInfoNeededQueryKey,
  Ws as getGetOnboardingInfoNeededQueryOptions,
  Is as getGetOnboardingStatesQueryKey,
  ws as getGetOnboardingStatesQueryOptions,
  Am as getGetOptInsQueryKey,
  Dm as getGetOptInsQueryOptions,
  wm as getGetOptOutEligibilityQueryKey,
  Qm as getGetOptOutEligibilityQueryOptions,
  Rm as getGetOrderQueryKey,
  Bm as getGetOrderQueryOptions,
  lt as getGetOrganizationQueryKey,
  gt as getGetOrganizationQueryOptions,
  Ny as getGetOwnerAuthQueryKey,
  Gy as getGetOwnerAuthQueryOptions,
  Se as getGetOwnerIsBeneficialOwnerQueryKey,
  ve as getGetOwnerIsBeneficialOwnerQueryOptions,
  js as getGetOwnersQueryKey,
  Zs as getGetOwnersQueryOptions,
  $p as getGetPADAgreementQueryKey,
  zp as getGetPADAgreementQueryOptions,
  aa as getGetParafinderBusinessTypesQueryKey,
  ua as getGetParafinderBusinessTypesQueryOptions,
  pa as getGetParafinderCapitalInfoQueryKey,
  _a as getGetParafinderCapitalInfoQueryOptions,
  ra as getGetParafinderQueryKey,
  sa as getGetParafinderQueryOptions,
  ca as getGetParafinderSummaryQueryKey,
  ya as getGetParafinderSummaryQueryOptions,
  rt as getGetPartnerApiKeyQueryKey,
  st as getGetPartnerApiKeyQueryOptions,
  Lt as getGetPartnerConfigsQueryKey,
  Wt as getGetPartnerConfigsQueryOptions,
  It as getGetPartnerPublicConfigsQueryKey,
  wt as getGetPartnerPublicConfigsQueryOptions,
  Ht as getGetPartnerSyncWebhooksQueryKey,
  Yt as getGetPartnerSyncWebhooksQueryOptions,
  Mt as getGetPartnerUsersQueryKey,
  kt as getGetPartnerUsersQueryOptions,
  Kp as getGetPartnerWidgetConfigsQueryKey,
  Pp as getGetPartnerWidgetConfigsQueryOptions,
  B as getGetPartnersDoordashAdminUsersQueryKey,
  C as getGetPartnersDoordashAdminUsersQueryOptions,
  z as getGetPartnersQueryKey,
  H as getGetPartnersQueryOptions,
  Z as getGetPartnersUnderwritingQueryKey,
  J as getGetPartnersUnderwritingQueryOptions,
  wd as getGetPdFeeDiscountProgramAccessRequestQueryKey,
  Qd as getGetPdFeeDiscountProgramAccessRequestQueryOptions,
  jd as getGetPdFeeDiscountProgramQueryKey,
  Zd as getGetPdFeeDiscountProgramQueryOptions,
  my as getGetPdOfferUrlQueryKey,
  ly as getGetPdOfferUrlQueryOptions,
  rp as getGetPdRevenueShareQueryKey,
  sp as getGetPdRevenueShareQueryOptions,
  Ay as getGetPdUtilityInfoQueryKey,
  Dy as getGetPdUtilityInfoQueryOptions,
  Rs as getGetPlaidInvestigationsQueryKey,
  Bs as getGetPlaidInvestigationsQueryOptions,
  Em as getGetRefundQueryKey,
  Sm as getGetRefundQueryOptions,
  w as getGetRepaymentPlanParametersQueryKey,
  Q as getGetRepaymentPlanParametersQueryOptions,
  ml as getGetRepaymentsQueryKey,
  ll as getGetRepaymentsQueryOptions,
  To as getGetRestrictionQueryKey,
  Io as getGetRestrictionQueryOptions,
  Wo as getGetRestrictionsDetailsQueryKey,
  Vo as getGetRestrictionsDetailsQueryOptions,
  is as getGetRunOfacScreeningResultsQueryKey,
  cs as getGetRunOfacScreeningResultsQueryOptions,
  dp as getGetRutterConnectionOptionsQueryKey,
  pp as getGetRutterConnectionOptionsQueryOptions,
  ap as getGetRutterConnectionsQueryKey,
  up as getGetRutterConnectionsQueryOptions,
  vd as getGetS3PartnerDashboardTableCsvQueryKey,
  Ad as getGetS3PartnerDashboardTableCsvQueryOptions,
  gp as getGetSardineSessionIdQueryKey,
  qp as getGetSardineSessionIdQueryOptions,
  ht as getGetSlackChannelsQueryKey,
  Kt as getGetSlackChannelsQueryOptions,
  bl as getGetStatementsQueryKey,
  xl as getGetStatementsQueryOptions,
  oe as getGetStatementsV2QueryKey,
  ae as getGetStatementsV2QueryOptions,
  Pd as getGetSyncAndMaybeMarkFailedMutationOptions,
  Od as getGetSyncQueryKey,
  hd as getGetSyncQueryOptions,
  s_ as getGetTemplateMockQueryKey,
  o_ as getGetTemplateMockQueryOptions,
  y_ as getGetTemplateSnapshotQueryKey,
  d_ as getGetTemplateSnapshotQueryOptions,
  u_ as getGetTemplateSnapshotsQueryKey,
  i_ as getGetTemplateSnapshotsQueryOptions,
  __ as getGetTemplateTypesQueryKey,
  m_ as getGetTemplateTypesQueryOptions,
  jp as getGetTemplatesQueryKey,
  Zp as getGetTemplatesQueryOptions,
  _s as getGetTermLoanProspectiveRepaymentsQueryKey,
  ms as getGetTermLoanProspectiveRepaymentsQueryOptions,
  El as getGetUnderwritingStatusForOrderQueryKey,
  Sl as getGetUnderwritingStatusForOrderQueryOptions,
  As as getKycRetryFields,
  nm as getLatestAttemptToUnderwriteIntroductoryOffers,
  $o as getLiens,
  _t as getLinkPartnerMutationOptions,
  Ii as getListApiRequestsQueryKey,
  wi as getListApiRequestsQueryOptions,
  zl as getListApplicationsQueryKey,
  Hl as getListApplicationsQueryOptions,
  wl as getListBankLinkingErrorQueryKey,
  Ql as getListBankLinkingErrorQueryOptions,
  yn as getListDatasetUploadsQueryKey,
  dn as getListDatasetUploadsQueryOptions,
  _n as getListManualDatasetUploadValidationResultsQueryKey,
  mn as getListManualDatasetUploadValidationResultsQueryOptions,
  it as getListOrganizationsQueryKey,
  ct as getListOrganizationsQueryOptions,
  Ji as getListPartnerScheduledMarketingEmailConfigsQueryKey,
  tc as getListPartnerScheduledMarketingEmailConfigsQueryOptions,
  dc as getListPdBusinessDetailsApplicationsQueryKey,
  pc as getListPdBusinessDetailsApplicationsQueryOptions,
  mc as getListPdBusinessDetailsBankInfoQueryKey,
  lc as getListPdBusinessDetailsBankInfoQueryOptions,
  qc as getListPdBusinessDetailsBusinessInfoQueryKey,
  fc as getListPdBusinessDetailsBusinessInfoQueryOptions,
  bc as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  xc as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  kc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Fc as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  hc as getListPdBusinessDetailsFundedProductsQueryKey,
  Kc as getListPdBusinessDetailsFundedProductsQueryOptions,
  Bc as getListPdBusinessDetailsOffersQueryKey,
  Cc as getListPdBusinessDetailsOffersQueryOptions,
  Sc as getListPdBusinessDetailsOwnerInfoQueryKey,
  vc as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Gc as getListPdBusinessTableRowsQueryKey,
  Tc as getListPdBusinessTableRowsQueryOptions,
  ny as getListPdCapitalProductApplicationRowsQueryKey,
  ry as getListPdCapitalProductApplicationRowsQueryOptions,
  iy as getListPdCapitalProductOfferRowsQueryKey,
  cy as getListPdCapitalProductOfferRowsQueryOptions,
  Wc as getListPdCapitalProductRowsQueryKey,
  Vc as getListPdCapitalProductRowsQueryOptions,
  cd as getListPdCrmDeliveryAttemptsQueryKey,
  yd as getListPdCrmDeliveryAttemptsQueryOptions,
  md as getListPdCrmEventInfoQueryKey,
  ld as getListPdCrmEventInfoQueryOptions,
  ad as getListPdCrmEventsQueryKey,
  ud as getListPdCrmEventsQueryOptions,
  Ey as getListPdCrmIntegrationsQueryKey,
  Sy as getListPdCrmIntegrationsQueryOptions,
  tp as getListPdFeeDiscountProgramCohortTableRowsQueryKey,
  ep as getListPdFeeDiscountProgramCohortTableRowsQueryOptions,
  $d as getListPdFeeDiscountProgramQueryKey,
  zd as getListPdFeeDiscountProgramQueryOptions,
  Xc as getListPdOrderRowsQueryKey,
  jc as getListPdOrderRowsQueryOptions,
  qy as getListPdPartnerEventListUserSubscriptionsQueryKey,
  fy as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  hy as getListPdPartnerEventListWebNotificationsQueryKey,
  Ky as getListPdPartnerEventListWebNotificationsQueryOptions,
  dy as getListPdSupportInfoQueryKey,
  py as getListPdSupportInfoQueryOptions,
  xd as getListPdWebhookEndpointsQueryKey,
  Md as getListPdWebhookEndpointsQueryOptions,
  Xy as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  jy as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  zy as getListPdWebhookLogsEventInfoQueryKey,
  Hy as getListPdWebhookLogsEventInfoQueryOptions,
  Wy as getListPdWebhookLogsEventsQueryKey,
  Vy as getListPdWebhookLogsEventsQueryOptions,
  Li as getListS3OfferCsvsQueryKey,
  Wi as getListS3OfferCsvsQueryOptions,
  Ud as getListS3PartnerDashboardTableCsvsQueryKey,
  Nd as getListS3PartnerDashboardTableCsvsQueryOptions,
  E_ as getListSardineDetailsQueryKey,
  S_ as getListSardineDetailsQueryOptions,
  Hi as getListSendMarketingEmailBatchesQueryKey,
  Yi as getListSendMarketingEmailBatchesQueryOptions,
  Tn as getListVersionedSchemasQueryKey,
  In as getListVersionedSchemasQueryOptions,
  Yp as getMarkPADAgreementSignedMutationOptions,
  ge as getMarketingGtmToolkit,
  ce as getMarketingIntegrations,
  Oe as getMarketingParagonToken,
  ha as getMerchantBankAccounts,
  ma as getMerchantBankAccountsId,
  qa as getMerchantBankAccountsIdBalances,
  Rp as getMerchantBankApplications,
  Mp as getMerchantBankApplicationsId,
  fu as getMerchantBankCardAccessToken,
  bu as getMerchantBankCardCardholderAccessToken,
  _u as getMerchantBankCardCardholders,
  Cu as getMerchantBankCardDisputes,
  au as getMerchantBankCards,
  Vu as getMerchantBankExternalAccounts,
  Xu as getMerchantBankExternalAccountsId,
  Qu as getMerchantBankExternalAccountsIdPlaidLinkToken,
  Tu as getMerchantBankExternalAccountsPlaidLinkToken,
  Ta as getMerchantBankInterest,
  M_ as getMerchantBankOffers,
  Qa as getMerchantBankRecipients,
  za as getMerchantBankRecipientsId,
  Ca as getMerchantBankTransactions,
  Fa as getMerchantBankTransactionsId,
  va as getMerchantBankTransferFees,
  Ua as getMerchantBankTransferLimits,
  Jy as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  td as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  Gp as getNaics,
  Tl as getNetSettlementsOptInMutationOptions,
  Dl as getNetSettlementsOptInStatus,
  Ei as getOfficerSubsidiaryRecords,
  Hn as getOnboardingBusinessRequirements,
  Qs as getOnboardingInfoNeeded,
  Ts as getOnboardingStates,
  vm as getOptIns,
  Im as getOptOutEligibility,
  Fm as getOrder,
  mt as getOrganization,
  Uy as getOwnerAuth,
  Ee as getOwnerIsBeneficialOwner,
  Xs as getOwners,
  Vp as getPADAgreement,
  na as getParafinder,
  oa as getParafinderBusinessTypes,
  da as getParafinderCapitalInfo,
  ia as getParafinderSummary,
  nt as getPartnerApiKey,
  Qt as getPartnerConfigs,
  Tt as getPartnerPublicConfigs,
  nc as getPartnerScheduledMarketingEmailConfigMutationOptions,
  zt as getPartnerSyncWebhooks,
  xt as getPartnerUsers,
  hp as getPartnerWidgetConfigs,
  $ as getPartners,
  R as getPartnersDoordashAdminUsers,
  j as getPartnersUnderwriting,
  Y_ as getPatchAuthManagementUseridSamlSamlconfigidMutationOptions,
  Ce as getPatchBeneficialOwnersIdMutationOptions,
  Ys as getPatchBusinessIdentitiesIdMutationOptions,
  on as getPatchBusinessOfficersIdMutationOptions,
  Vr as getPatchCapitalProductIntentsMutationOptions,
  ar as getPatchCapitalProductsMutationOptions,
  mm as getPatchCardholdersCardholderidMutationOptions,
  T as getPatchCashAdvancePausesP1MutationOptions,
  ds as getPatchClearWatchlistHitsIdMutationOptions,
  zn as getPatchDataingestionStripeIngestionsMutationOptions,
  _o as getPatchFlexLoanAgeMutationOptions,
  qo as getPatchFlexLoanFundMutationOptions,
  lo as getPatchFlexLoanOwnershipMutationOptions,
  Xo as getPatchLiensIdMutationOptions,
  pu as getPatchMerchantBankCardCardholdersIdMutationOptions,
  ni as getPatchMerchantBankExternalAccountsIdMutationOptions,
  ai as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  A_ as getPatchMerchantBankMerchantConfigMutationOptions,
  ou as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  Ja as getPatchMerchantBankRecipientsIdMutationOptions,
  x_ as getPatchMfaExtendSessionMutationOptions,
  De as getPatchOwnerIsBeneficialOwnerMutationOptions,
  to as getPatchOwnersIdMutationOptions,
  $t as getPatchPartnerConfigsIdMutationOptions,
  ee as getPatchPartnerSyncWebhooksIdMutationOptions,
  Ct as getPatchPartnerUsersMutationOptions,
  Gt as getPatchPartnerUsersV2MutationOptions,
  xp as getPatchPartnerWidgetConfigsMutationOptions,
  et as getPatchPartnersIdMutationOptions,
  vs as getPatchPersonaFallbackInquiryMutationOptions,
  Bl as getPatchRootMutationOptions,
  Hm as getPatchSandboxCapitalProductFundMutationOptions,
  Xm as getPatchSandboxCapitalProductFundingChecklistMutationOptions,
  Zm as getPatchSandboxCapitalProductTopUpMutationOptions,
  il as getPatchSandboxRepaymentsMutationOptions,
  n_ as getPatchTemplatesMutationOptions,
  Xd as getPdFeeDiscountProgram,
  Id as getPdFeeDiscountProgramAccessRequest,
  _y as getPdOfferUrl,
  np as getPdRevenueShare,
  vy as getPdUtilityInfo,
  Fs as getPlaidInvestigations,
  Ri as getPostAccountingInfoMutationOptions,
  ao as getPostAmazonRedirectUrlMutationOptions,
  em as getPostAuthEmailOtpStartMutationOptions,
  J_ as getPostAuthEmailOtpV2VerifyMutationOptions,
  j_ as getPostAuthEmailOtpVerifyMutationOptions,
  G_ as getPostAuthLoginStartMutationOptions,
  U_ as getPostAuthLoginValidateOtpMutationOptions,
  V_ as getPostAuthManagementUseridSamlMutationOptions,
  I_ as getPostAuthTokenExchangeMutationOptions,
  re as getPostAuthTokensMutationOptions,
  ke as getPostBeneficialOwnersMutationOptions,
  Tm as getPostBulkOptInMutationOptions,
  F as getPostBusinessAnnotationsMutationOptions,
  Bo as getPostBusinessAuthLinkMutationOptions,
  P as getPostBusinessCoresMutationOptions,
  no as getPostBusinessMutationOptions,
  en as getPostBusinessOfficersMutationOptions,
  uc as getPostBusinessesNotificationsMutationOptions,
  Or as getPostCapitalProductApplicationMutationOptions,
  yo as getPostCapitalProductBusinessMutationOptions,
  xr as getPostCapitalProductNetTopUpMutationOptions,
  dr as getPostCapitalProductOfferCollectionMutationOptions,
  fi as getPostCardCloseAccountMutationOptions,
  xo as getPostCardsMutationOptions,
  D as getPostCashAdvancePausesMutationOptions,
  un as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  Mn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  Cn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  Nn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  Vn as getPostDataingestionStripeIngestionsMutationOptions,
  _i as getPostDebtsCheckCompleteMutationOptions,
  di as getPostDebtsMutationOptions,
  Ao as getPostDocumentUploadGroupMutationOptions,
  bi as getPostFinancialReviewsMutationOptions,
  h_ as getPostForgiveCapitalFeeMutationOptions,
  ks as getPostFundingAccountsMutationOptions,
  Gs as getPostKycRetryMutationOptions,
  f_ as getPostManualRepaymentMutationOptions,
  mp as getPostMarkAccountingIntegrationSeenMutationOptions,
  _e as getPostMarketingIntegrationsMutationOptions,
  ie as getPostMarketingReviewMutationOptions,
  xa as getPostMerchantBankAccountsMutationOptions,
  Sp as getPostMerchantBankApplicationsMutationOptions,
  so as getPostMerchantBankBusinessMutationOptions,
  qu as getPostMerchantBankCardActivateMutationOptions,
  Pu as getPostMerchantBankCardApplePayMutationOptions,
  Gu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  Uu as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  Au as getPostMerchantBankCardDisputesMutationOptions,
  Bu as getPostMerchantBankCardGooglePayMutationOptions,
  Fu as getPostMerchantBankCardStateTransitionMutationOptions,
  yu as getPostMerchantBankCardsMutationOptions,
  Yu as getPostMerchantBankExternalAccountsMutationOptions,
  si as getPostMerchantBankExternalBankTransfersMutationOptions,
  ka as getPostMerchantBankInnerTransfersMutationOptions,
  B_ as getPostMerchantBankOffersMutationOptions,
  ru as getPostMerchantBankRecipientTransfersMutationOptions,
  eu as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  $a as getPostMerchantBankRecipientsMutationOptions,
  Qp as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  Wp as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  P_ as getPostMfaVerifyAccountDetailsMutationOptions,
  Di as getPostOfficerSubsidiaryRecordsMutationOptions,
  Zo as getPostOptInMutationOptions,
  Nm as getPostOptInsMutationOptions,
  Wm as getPostOptOutMutationOptions,
  jt as getPostPartnerSyncWebhooksMutationOptions,
  Rt as getPostPartnerUsersMutationOptions,
  Ut as getPostPartnerUsersV2MutationOptions,
  X as getPostPartnersMutationOptions,
  Qo as getPostRestrictionMutationOptions,
  cp as getPostRutterConnectionsMutationOptions,
  nl as getPostSandboxAmazonLockBankAccountMutationOptions,
  sl as getPostSandboxBreakBankAccountMutationOptions,
  tl as getPostSandboxCapitalProductPayOffMutationOptions,
  yl as getPostSandboxMerchantBankSetupBusinessMutationOptions,
  al as getPostSandboxRepaymentsMutationOptions,
  Op as getPostSardineSessionIdMutationOptions,
  Oo as getPostSetupAmazonTopUpMutationOptions,
  ft as getPostSlackAuthorizeMutationOptions,
  io as getPostSpdMutationOptions,
  Ci as getPostTaxRecordsMutationOptions,
  g_ as getPostTemplateTypesMutationOptions,
  t_ as getPostTemplatesMutationOptions,
  cc as getPostTermsOfServiceMarkShownMutationOptions,
  as as getPostTriggerRunCelticsChecklistMutationOptions,
  Ap as getPostWebhooksPlaidGeneralMutationOptions,
  Dn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  $m as getPutSandboxCapitalProductTestOfferMutationOptions,
  pl as getPutSandboxFeeDiscountMutationOptions,
  Cm as getRefund,
  I as getRepaymentPlanParameters,
  _l as getRepayments,
  pd as getResendCrmAttemptMutationOptions,
  nd as getResendWebhookAttemptMutationOptions,
  Go as getRestriction,
  Lo as getRestrictionsDetails,
  us as getRunOfacScreeningResults,
  yp as getRutterConnectionOptions,
  op as getRutterConnections,
  Sd as getS3PartnerDashboardTableCsv,
  lp as getSardineSessionId,
  ji as getSendMarketingEmailsMutationOptions,
  Ot as getSlackChannels,
  Pl as getStatements,
  se as getStatementsV2,
  fd as getSync,
  Kd as getSyncAndMaybeMarkFailed,
  r_ as getTemplateMock,
  c_ as getTemplateSnapshot,
  a_ as getTemplateSnapshots,
  p_ as getTemplateTypes,
  Xp as getTemplates,
  ps as getTermLoanProspectiveRepayments,
  Cl as getUnderwritingStatusForOrder,
  Ks as getUpdateBankAccountInfoMutationOptions,
  Fy as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  By as getUpdatePdPartnerEventUpdateUserSubscriptionsForEventTypeMutationOptions,
  Ed as getUpdatePdWebhookEndpointMutationOptions,
  jl as kitchen,
  pt as linkPartner,
  Ti as listApiRequests,
  $l as listApplications,
  Il as listBankLinkingError,
  cn as listDatasetUploads,
  pn as listManualDatasetUploadValidationResults,
  ut as listOrganizations,
  Zi as listPartnerScheduledMarketingEmailConfigs,
  yc as listPdBusinessDetailsApplications,
  _c as listPdBusinessDetailsBankInfo,
  gc as listPdBusinessDetailsBusinessInfo,
  Pc as listPdBusinessDetailsFundedProductDetails,
  Mc as listPdBusinessDetailsFundedProductPaymentProgress,
  Oc as listPdBusinessDetailsFundedProducts,
  Rc as listPdBusinessDetailsOffers,
  Ec as listPdBusinessDetailsOwnerInfo,
  Nc as listPdBusinessTableRows,
  ey as listPdCapitalProductApplicationRows,
  uy as listPdCapitalProductOfferRows,
  Lc as listPdCapitalProductRows,
  id as listPdCrmDeliveryAttempts,
  _d as listPdCrmEventInfo,
  od as listPdCrmEvents,
  Cy as listPdCrmIntegrations,
  Vd as listPdFeeDiscountProgram,
  Jd as listPdFeeDiscountProgramCohortTableRows,
  Yc as listPdOrderRows,
  gy as listPdPartnerEventListUserSubscriptions,
  Oy as listPdPartnerEventListWebNotifications,
  yy as listPdSupportInfo,
  bd as listPdWebhookEndpoints,
  Yy as listPdWebhookLogsDeliveryAttempts,
  $y as listPdWebhookLogsEventInfo,
  Ly as listPdWebhookLogsEvents,
  Qi as listS3OfferCsvs,
  Dd as listS3PartnerDashboardTableCsvs,
  C_ as listSardineDetails,
  zi as listSendMarketingEmailBatches,
  Gn as listVersionedSchemas,
  Hp as markPADAgreementSigned,
  Zy as multiWebhookPdWebhookLogsDeliveryAttempts,
  Gl as netSettlementsOptIn,
  ec as partnerScheduledMarketingEmailConfig,
  H_ as patchAuthManagementUseridSamlSamlconfigid,
  Be as patchBeneficialOwnersId,
  Hs as patchBusinessIdentitiesId,
  sn as patchBusinessOfficersId,
  Wr as patchCapitalProductIntents,
  or as patchCapitalProducts,
  _m as patchCardholdersCardholderid,
  G as patchCashAdvancePausesP1,
  ys as patchClearWatchlistHitsId,
  $n as patchDataingestionStripeIngestions,
  po as patchFlexLoanAge,
  go as patchFlexLoanFund,
  mo as patchFlexLoanOwnership,
  Yo as patchLiensId,
  du as patchMerchantBankCardCardholdersId,
  ei as patchMerchantBankExternalAccountsId,
  oi as patchMerchantBankExternalBankTransfersId,
  v_ as patchMerchantBankMerchantConfig,
  su as patchMerchantBankRecipientTransfersId,
  Za as patchMerchantBankRecipientsId,
  b_ as patchMfaExtendSession,
  Ae as patchOwnerIsBeneficialOwner,
  Js as patchOwnersId,
  Vt as patchPartnerConfigsId,
  te as patchPartnerSyncWebhooksId,
  Bt as patchPartnerUsers,
  Nt as patchPartnerUsersV2,
  bp as patchPartnerWidgetConfigs,
  tt as patchPartnersId,
  Ss as patchPersonaFallbackInquiry,
  Rl as patchRoot,
  zm as patchSandboxCapitalProductFund,
  Ym as patchSandboxCapitalProductFundingChecklist,
  jm as patchSandboxCapitalProductTopUp,
  ul as patchSandboxRepayments,
  e_ as patchTemplates,
  Fi as postAccountingInfo,
  oo as postAmazonRedirectUrl,
  tm as postAuthEmailOtpStart,
  Z_ as postAuthEmailOtpV2Verify,
  X_ as postAuthEmailOtpVerify,
  N_ as postAuthLoginStart,
  D_ as postAuthLoginValidateOtp,
  W_ as postAuthManagementUseridSaml,
  T_ as postAuthTokenExchange,
  ne as postAuthTokens,
  Me as postBeneficialOwners,
  Gm as postBulkOptIn,
  eo as postBusiness,
  k as postBusinessAnnotations,
  Ro as postBusinessAuthLink,
  K as postBusinessCores,
  tn as postBusinessOfficers,
  ac as postBusinessesNotifications,
  fr as postCapitalProductApplication,
  co as postCapitalProductBusiness,
  br as postCapitalProductNetTopUp,
  yr as postCapitalProductOfferCollection,
  qi as postCardCloseAccount,
  bo as postCards,
  A as postCashAdvancePauses,
  an as postDataingestionOneschemaGenerateJwt,
  xn as postDataingestionS3DatasetIntegrations,
  Bn as postDataingestionS3OrgDatasetIntegrations,
  Un as postDataingestionS3RunDatasetIngestion,
  Wn as postDataingestionStripeIngestions,
  yi as postDebts,
  pi as postDebtsCheckComplete,
  vo as postDocumentUploadGroup,
  Pi as postFinancialReviews,
  O_ as postForgiveCapitalFee,
  Ms as postFundingAccounts,
  Ns as postKycRetry,
  q_ as postManualRepayment,
  _p as postMarkAccountingIntegrationSeen,
  pe as postMarketingIntegrations,
  ue as postMarketingReview,
  ba as postMerchantBankAccounts,
  Ep as postMerchantBankApplications,
  ro as postMerchantBankBusiness,
  gu as postMerchantBankCardActivate,
  Ku as postMerchantBankCardApplePay,
  Nu as postMerchantBankCardDisputeChargebackCredit,
  Du as postMerchantBankCardDisputeStateTransition,
  vu as postMerchantBankCardDisputes,
  Ru as postMerchantBankCardGooglePay,
  ku as postMerchantBankCardStateTransition,
  cu as postMerchantBankCards,
  Hu as postMerchantBankExternalAccounts,
  ri as postMerchantBankExternalBankTransfers,
  Ma as postMerchantBankInnerTransfers,
  R_ as postMerchantBankOffers,
  nu as postMerchantBankRecipientTransfers,
  Va as postMerchantBankRecipients,
  tu as postMerchantBankRecipientsIdPaymentRails,
  wp as postMfaOtpSendPersonidPhonenumbertype,
  Lp as postMfaOtpVerifyPersonidPhonenumbertype,
  K_ as postMfaVerifyAccountDetails,
  Ai as postOfficerSubsidiaryRecords,
  jo as postOptIn,
  Um as postOptIns,
  Lm as postOptOut,
  Xt as postPartnerSyncWebhooks,
  Ft as postPartnerUsers,
  Dt as postPartnerUsersV2,
  Y as postPartners,
  wo as postRestriction,
  ip as postRutterConnections,
  el as postSandboxAmazonLockBankAccount,
  rl as postSandboxBreakBankAccount,
  Jm as postSandboxCapitalProductPayOff,
  cl as postSandboxMerchantBankSetupBusiness,
  ol as postSandboxRepayments,
  fp as postSardineSessionId,
  fo as postSetupAmazonTopUp,
  qt as postSlackAuthorize,
  uo as postSpd,
  Bi as postTaxRecords,
  l_ as postTemplateTypes,
  Jp as postTemplates,
  ic as postTermsOfServiceMarkShown,
  os as postTriggerRunCelticsChecklist,
  vp as postWebhooksPlaidGeneral,
  ek as publicApiPaths,
  An as putDataingestionS3DatasetIngestionConfigs,
  Vm as putSandboxCapitalProductTestOffer,
  dl as putSandboxFeeDiscount,
  dd as resendCrmAttempt,
  ed as resendWebhookAttempt,
  Xi as sendMarketingEmails,
  hs as updateBankAccountInfo,
  ky as updatePdPartnerEventUpdateUserSubscription,
  Ry as updatePdPartnerEventUpdateUserSubscriptionsForEventType,
  Cd as updatePdWebhookEndpoint,
  HM as useAmazonRPCPreLogin,
  ZM as useAttemptAmazonBankVerification,
  zb as useAttemptSync,
  Rb as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  Qb as useBatchResendWebhookAttempt,
  $h as useBenchmarkGetCapitalProductOwnership,
  mb as useCountPdBusinessTableRows,
  hb as useCountPdCapitalProductApplicationRow,
  Pb as useCountPdCapitalProductOfferRow,
  gb as useCountPdCapitalProductRow,
  fb as useCountPdOrderRow,
  AM as useCreateAttemptToUnderwriteBnplOrder,
  eM as useCreateAttemptToUnderwriteIntroductoryOffers,
  iK as useCreateBankAccountInfo,
  IM as useCreateBeneficialOwner,
  Xh as useCreateCapitalProductIntents,
  tK as useCreateCapitalProductSuperApplicationActions,
  Jh as useCreateCapitalProductSuperApplications,
  Dh as useCreateCapitalProducts,
  Hh as useCreateOfferedCapitalProductFeeDiscount,
  FO as useCreateOrganization,
  MO as useCreatePartnerApiKey,
  ax as useCreatePdFeeDiscountProgram,
  sx as useCreatePdFeeDiscountProgramAccessRequest,
  Bb as useCreatePdPartnerEventMarkNotificationRead,
  jb as useCreatePdWebhookEndpoint,
  _K as useCreatePlaidInvestigation,
  JM as useCreatePlaidLinkage,
  ZP as useCreateS3OfferCsv,
  nx as useCreateS3PartnerDashboardTableCsv,
  zM as useCreateUnderwritingAttempt,
  VM as useCreateUnverifiedAmazonFundingAccount,
  WM as useCreateUnverifiedFundingAccount,
  jM as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  tk as useCreateZendeskTicket,
  Yx as useDeleteAuthManagementUseridSamlSamlconfigid,
  QM as useDeleteBeneficialOwner,
  rh as useDeleteBeneficialOwnersId,
  mh as useDeleteBusinessOfficersId,
  gO as useDeleteCashAdvancePausesP1,
  ZO as useDeleteMarketingIntegrationsVendor,
  vP as useDeleteMerchantBankExternalAccountsId,
  cP as useDeleteMerchantBankRecipientsId,
  NO as useDeleteOrganizationDeleteUsers,
  UO as useDeletePartnerDeleteUsers,
  VO as useDeletePartnerSyncWebhooksId,
  Zb as useDeletePdWebhookEndpoint,
  SO as useDeleteSlackChannelsId,
  XM as useDisputeTransaction,
  Ub as useExperimentalCapitalProductOfferClosedSandbox,
  Db as useExperimentalCapitalProductOfferCreatedSandbox,
  WP as useGetAccountingInfo,
  Px as useGetAmazonFundingAccountLock,
  YP as useGetAnalyticsCubeToken,
  GM as useGetApplication,
  zx as useGetAuthManagementP1Saml,
  uK as useGetBankAccountInfos,
  uh as useGetBanks,
  eh as useGetBeneficialOwners,
  dO as useGetBusinessAnnotations,
  cO as useGetBusinessCores,
  OK as useGetBusinessIdentities,
  ph as useGetBusinessOfficers,
  dh as useGetBusinessTableRowsCard,
  yh as useGetBusinessTableRowsLoan,
  ch as useGetBusinessTableRowsMca,
  ih as useGetBusinessTableRowsProductAgnostic,
  VK as useGetBusinessesDailySalesRecords,
  rb as useGetBusinessesNotifications,
  AK as useGetBusinessesProducts,
  RM as useGetBusinessesShowTopUpOffer,
  eK as useGetCalculatedChunkValues,
  Th as useGetCapitalProductActivities,
  Qh as useGetCapitalProductApplicationSnapshots,
  Ih as useGetCapitalProductApplications,
  Yh as useGetCapitalProductIntents,
  Wh as useGetCapitalProductNetTopUpItemization,
  Nh as useGetCapitalProductOfferCollection,
  vh as useGetCapitalProductProspectiveTermsOfService,
  zh as useGetCapitalProductRepaymentSchedule,
  Vh as useGetCapitalProductStateDisclosure,
  Ah as useGetCapitalProducts,
  oM as useGetCardAccessToken,
  nM as useGetCardBalances,
  aM as useGetCardLifecycleStates,
  iM as useGetCardPendingTransactions,
  uM as useGetCardTransactions,
  rM as useGetCardholders,
  SK as useGetCards,
  mO as useGetCashAdvancePauses,
  Zh as useGetCounteroffer,
  Mh as useGetDataingestionS3DatasetIngestionConfigs,
  Kh as useGetDataingestionS3DatasetIntegrations,
  hh as useGetDataingestionS3DatasetUploadValidationResults,
  Oh as useGetDataingestionS3DatasetUploads,
  bh as useGetDataingestionS3OrgDatasetIntegrations,
  Bh as useGetDataingestionStripeIngestions,
  IP as useGetDebtChecks,
  NP as useGetDebts,
  UK as useGetDocumentUploadGroup,
  GK as useGetDocumentUploadList,
  EM as useGetEarlyManualRepaymentWhitelist,
  QP as useGetFinancialReviews,
  nK as useGetFlexLoanMinimumRepaymentSchedule,
  yK as useGetFundingAccounts,
  BM as useGetFundingPlaidLinkToken,
  OO as useGetFutureActivities,
  lK as useGetKycRetryFields,
  tM as useGetLatestAttemptToUnderwriteIntroductoryOffers,
  QK as useGetLiens,
  JO as useGetMarketingGtmToolkit,
  XO as useGetMarketingIntegrations,
  th as useGetMarketingParagonToken,
  ZK as useGetMerchantBankAccounts,
  XK as useGetMerchantBankAccountsId,
  jK as useGetMerchantBankAccountsIdBalances,
  Ox as useGetMerchantBankApplications,
  fx as useGetMerchantBankApplicationsId,
  OP as useGetMerchantBankCardAccessToken,
  KP as useGetMerchantBankCardCardholderAccessToken,
  qP as useGetMerchantBankCardCardholders,
  xP as useGetMerchantBankCardDisputes,
  mP as useGetMerchantBankCards,
  CP as useGetMerchantBankExternalAccounts,
  SP as useGetMerchantBankExternalAccountsId,
  BP as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  RP as useGetMerchantBankExternalAccountsPlaidLinkToken,
  oP as useGetMerchantBankInterest,
  Ix as useGetMerchantBankOffers,
  aP as useGetMerchantBankRecipients,
  iP as useGetMerchantBankRecipientsId,
  nP as useGetMerchantBankTransactions,
  eP as useGetMerchantBankTransactionsId,
  rP as useGetMerchantBankTransferFees,
  sP as useGetMerchantBankTransferLimits,
  bx as useGetNaics,
  DM as useGetNetSettlementsOptInStatus,
  zP as useGetOfficerSubsidiaryRecords,
  Sh as useGetOnboardingBusinessRequirements,
  fK as useGetOnboardingInfoNeeded,
  qK as useGetOnboardingStates,
  dM as useGetOptIns,
  mM as useGetOptOutEligibility,
  cM as useGetOrder,
  BO as useGetOrganization,
  Ab as useGetOwnerAuth,
  oh as useGetOwnerIsBeneficialOwner,
  KK as useGetOwners,
  kx as useGetPADAgreement,
  $K as useGetParafinder,
  zK as useGetParafinderBusinessTypes,
  YK as useGetParafinderCapitalInfo,
  HK as useGetParafinderSummary,
  xO as useGetPartnerApiKey,
  wO as useGetPartnerConfigs,
  IO as useGetPartnerPublicConfigs,
  LO as useGetPartnerSyncWebhooks,
  vO as useGetPartnerUsers,
  gx as useGetPartnerWidgetConfigs,
  hO as useGetPartners,
  _O as useGetPartnersDoordashAdminUsers,
  PO as useGetPartnersUnderwriting,
  ux as useGetPdFeeDiscountProgram,
  rx as useGetPdFeeDiscountProgramAccessRequest,
  Mb as useGetPdOfferUrl,
  cx as useGetPdRevenueShare,
  vb as useGetPdUtilityInfo,
  pK as useGetPlaidInvestigations,
  yM as useGetRefund,
  fO as useGetRepaymentPlanParameters,
  FM as useGetRepayments,
  TK as useGetRestriction,
  wK as useGetRestrictionsDetails,
  sK as useGetRunOfacScreeningResults,
  px as useGetRutterConnectionOptions,
  yx as useGetRutterConnections,
  tx as useGetS3PartnerDashboardTableCsv,
  mx as useGetSardineSessionId,
  EO as useGetSlackChannels,
  CM as useGetStatements,
  HO as useGetStatementsV2,
  Hb as useGetSync,
  Yb as useGetSyncAndMaybeMarkFailed,
  Ex as useGetTemplateMock,
  vx as useGetTemplateSnapshot,
  Sx as useGetTemplateSnapshots,
  Ax as useGetTemplateTypes,
  Rx as useGetTemplates,
  aK as useGetTermLoanProspectiveRepayments,
  vM as useGetUnderwritingStatusForOrder,
  RO as useLinkPartner,
  XP as useListApiRequests,
  TM as useListApplications,
  NM as useListBankLinkingError,
  qh as useListDatasetUploads,
  fh as useListManualDatasetUploadValidationResults,
  kO as useListOrganizations,
  eb as useListPartnerScheduledMarketingEmailConfigs,
  ab as useListPdBusinessDetailsApplications,
  ub as useListPdBusinessDetailsBankInfo,
  ib as useListPdBusinessDetailsBusinessInfo,
  yb as useListPdBusinessDetailsFundedProductDetails,
  db as useListPdBusinessDetailsFundedProductPaymentProgress,
  cb as useListPdBusinessDetailsFundedProducts,
  pb as useListPdBusinessDetailsOffers,
  _b as useListPdBusinessDetailsOwnerInfo,
  lb as useListPdBusinessTableRows,
  Kb as useListPdCapitalProductApplicationRows,
  bb as useListPdCapitalProductOfferRows,
  qb as useListPdCapitalProductRows,
  Wb as useListPdCrmDeliveryAttempts,
  $b as useListPdCrmEventInfo,
  Lb as useListPdCrmEvents,
  Sb as useListPdCrmIntegrations,
  ox as useListPdFeeDiscountProgram,
  ix as useListPdFeeDiscountProgramCohortTableRows,
  Ob as useListPdOrderRows,
  kb as useListPdPartnerEventListUserSubscriptions,
  Fb as useListPdPartnerEventListWebNotifications,
  xb as useListPdSupportInfo,
  Xb as useListPdWebhookEndpoints,
  Tb as useListPdWebhookLogsDeliveryAttempts,
  Gb as useListPdWebhookLogsEventInfo,
  Nb as useListPdWebhookLogsEvents,
  jP as useListS3OfferCsvs,
  ex as useListS3PartnerDashboardTableCsvs,
  Qx as useListSardineDetails,
  JP as useListSendMarketingEmailBatches,
  Rh as useListVersionedSchemas,
  Fx as useMarkPADAgreementSigned,
  Ib as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  UM as useNetSettlementsOptIn,
  nb as usePartnerScheduledMarketingEmailConfig,
  Xx as usePatchAuthManagementUseridSamlSamlconfigid,
  wM as usePatchBeneficialOwner,
  sh as usePatchBeneficialOwnersId,
  hK as usePatchBusinessIdentitiesId,
  lh as usePatchBusinessOfficersId,
  jh as usePatchCapitalProductIntents,
  Uh as usePatchCapitalProducts,
  sM as usePatchCardholdersCardholderid,
  qO as usePatchCashAdvancePausesP1,
  oK as usePatchClearWatchlistHitsId,
  Eh as usePatchDataingestionStripeIngestions,
  RK as usePatchFlexLoanAge,
  CK as usePatchFlexLoanFund,
  BK as usePatchFlexLoanOwnership,
  LK as usePatchLiensId,
  gP as usePatchMerchantBankCardCardholdersId,
  AP as usePatchMerchantBankExternalAccountsId,
  UP as usePatchMerchantBankExternalBankTransfersId,
  Lx as usePatchMerchantBankMerchantConfig,
  _P as usePatchMerchantBankRecipientTransfersId,
  yP as usePatchMerchantBankRecipientsId,
  Tx as usePatchMfaExtendSession,
  ah as usePatchOwnerIsBeneficialOwner,
  PK as usePatchOwnersId,
  QO as usePatchPartnerConfigsId,
  $O as usePatchPartnerSyncWebhooksId,
  DO as usePatchPartnerUsers,
  TO as usePatchPartnerUsersV2,
  qx as usePatchPartnerWidgetConfigs,
  bO as usePatchPartnersId,
  mK as usePatchPersonaFallbackInquiry,
  SM as usePatchRoot,
  qM as usePatchSandboxCapitalProductFund,
  fM as usePatchSandboxCapitalProductFundingChecklist,
  OM as usePatchSandboxCapitalProductTopUp,
  xM as usePatchSandboxRepayments,
  Cx as usePatchTemplates,
  VP as usePostAccountingInfo,
  MK as usePostAmazonRedirectUrl,
  Jx as usePostAuthEmailOtpStart,
  Zx as usePostAuthEmailOtpV2Verify,
  jx as usePostAuthEmailOtpVerify,
  Vx as usePostAuthLoginStart,
  Wx as usePostAuthLoginValidateOtp,
  Hx as usePostAuthManagementUseridSaml,
  $x as usePostAuthTokenExchange,
  zO as usePostAuthTokens,
  nh as usePostBeneficialOwners,
  _M as usePostBulkOptIn,
  bK as usePostBusiness,
  pO as usePostBusinessAnnotations,
  DK as usePostBusinessAuthLink,
  yO as usePostBusinessCores,
  _h as usePostBusinessOfficers,
  sb as usePostBusinessesNotifications,
  wh as usePostCapitalProductApplication,
  FK as usePostCapitalProductBusiness,
  Lh as usePostCapitalProductNetTopUp,
  Gh as usePostCapitalProductOfferCollection,
  wP as usePostCardCloseAccount,
  vK as usePostCards,
  lO as usePostCashAdvancePauses,
  gh as usePostDataingestionOneschemaGenerateJwt,
  Ph as usePostDataingestionS3DatasetIntegrations,
  xh as usePostDataingestionS3OrgDatasetIntegrations,
  Fh as usePostDataingestionS3RunDatasetIngestion,
  Ch as usePostDataingestionStripeIngestions,
  GP as usePostDebts,
  TP as usePostDebtsCheckComplete,
  NK as usePostDocumentUploadGroup,
  LP as usePostFinancialReviews,
  Nx as usePostForgiveCapitalFee,
  dK as usePostFundingAccounts,
  gK as usePostKycRetry,
  Ux as usePostManualRepayment,
  _x as usePostMarkAccountingIntegrationSeen,
  jO as usePostMarketingIntegrations,
  YO as usePostMarketingReview,
  JK as usePostMerchantBankAccounts,
  hx as usePostMerchantBankApplications,
  xK as usePostMerchantBankBusiness,
  fP as usePostMerchantBankCardActivate,
  hP as usePostMerchantBankCardApplePay,
  FP as usePostMerchantBankCardDisputeChargebackCredit,
  kP as usePostMerchantBankCardDisputeStateTransition,
  MP as usePostMerchantBankCardDisputes,
  bP as usePostMerchantBankCardGooglePay,
  PP as usePostMerchantBankCardStateTransition,
  lP as usePostMerchantBankCards,
  EP as usePostMerchantBankExternalAccounts,
  DP as usePostMerchantBankExternalBankTransfers,
  tP as usePostMerchantBankInnerTransfers,
  wx as usePostMerchantBankOffers,
  pP as usePostMerchantBankRecipientTransfers,
  uP as usePostMerchantBankRecipients,
  dP as usePostMerchantBankRecipientsIdPaymentRails,
  xx as usePostMfaOtpSendPersonidPhonenumbertype,
  Mx as usePostMfaOtpVerifyPersonidPhonenumbertype,
  Gx as usePostMfaVerifyAccountDetails,
  HP as usePostOfficerSubsidiaryRecords,
  WK as usePostOptIn,
  pM as usePostOptIns,
  lM as usePostOptOut,
  WO as usePostPartnerSyncWebhooks,
  AO as usePostPartnerUsers,
  GO as usePostPartnerUsersV2,
  KO as usePostPartners,
  IK as usePostRestriction,
  dx as usePostRutterConnections,
  KM as usePostSandboxAmazonLockBankAccount,
  PM as usePostSandboxBreakBankAccount,
  hM as usePostSandboxCapitalProductPayOff,
  MM as usePostSandboxMerchantBankSetupBusiness,
  bM as usePostSandboxRepayments,
  lx as usePostSardineSessionId,
  EK as usePostSetupAmazonTopUp,
  CO as usePostSlackAuthorize,
  kK as usePostSpd,
  $P as usePostTaxRecords,
  Dx as usePostTemplateTypes,
  Bx as usePostTemplates,
  ob as usePostTermsOfServiceMarkShown,
  rK as usePostTriggerRunCelticsChecklist,
  Kx as usePostWebhooksPlaidGeneral,
  kh as usePutDataingestionS3DatasetIngestionConfigs,
  gM as usePutSandboxCapitalProductTestOffer,
  kM as usePutSandboxFeeDiscount,
  Vb as useResendCrmAttempt,
  wb as useResendWebhookAttempt,
  tb as useSendMarketingEmails,
  LM as useSubmitKYC,
  cK as useUpdateBankAccountInfo,
  YM as useUpdateBoostCompletion,
  Cb as useUpdatePdPartnerEventUpdateUserSubscription,
  Eb as useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType,
  Jb as useUpdatePdWebhookEndpoint,
  $M as useUploadBankStatements
};
