import { createRoot } from 'react-dom/client'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import { App } from '@/src/routes/App'
import {
  IS_DEV_LIKE_ENVIRONMENT,
  ENVIRONMENT,
  METICULOUS_PROJECT_ID,
  SENTRY_KEY,
  SENTRY_ORG,
} from '@/parafin.config'
import { initializeErrorReporting } from '@parafin/error-handling'
import './index.css'

initializeErrorReporting({
  environment: ENVIRONMENT,
  sentryKey: SENTRY_KEY,
  sentryOrg: SENTRY_ORG,
  enabled: ENVIRONMENT !== 'local',
  release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
})
;(async () => {
  if (IS_DEV_LIKE_ENVIRONMENT) {
    await tryLoadAndStartRecorder({
      projectId: METICULOUS_PROJECT_ID,
      isProduction: false,
    })
  }
  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(<App />)
})()
