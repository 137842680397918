import { ReactNode } from 'react'
import { ExperimentProvider as BaseExperimentProvider } from '@parafin/experimentation'
import { ENVIRONMENT, STATSIG_KEY } from '@/parafin.config'
import { FullScreenLoading } from '../ui/components/Loading'

export const STATSIG_GATES = {
  showWebhookEndpoints: 'pd-new-webhook-endpoints',
  showNotification: 'partner-event-emails',
  enableMediciLogin: 'pd-enable-medici-login',
  showPlayground: 'pd-sandbox-playground',
  showPromotions: 'pd-fee-discount-feature',
  showOrders: 'pd-show-bnpl-orders',
  showAuthenticationSettings: 'pd-show-authentications-settings',
}

export const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  return (
    <BaseExperimentProvider
      statsigKey={STATSIG_KEY}
      environment={ENVIRONMENT}
      loadingComponent={<FullScreenLoading />}
    >
      {children}
    </BaseExperimentProvider>
  )
}
