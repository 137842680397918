const checkVariable = (variable?: string) => {
  if (!variable) throw new Error('Missing environment variable')
  return variable
}

export const ENVIRONMENT = checkVariable(import.meta.env.VITE_ENVIRONMENT) as
  | 'production'
  | 'development'
  | 'local'

export const IS_DEV_LIKE_ENVIRONMENT =
  ENVIRONMENT === 'development' || ENVIRONMENT === 'local'

export const BASE_URL = checkVariable(import.meta.env.VITE_API)
export const AUTH_URL = checkVariable(import.meta.env.VITE_AUTH_URL)
export const AUTH_ID = checkVariable(import.meta.env.VITE_AUTH_ID)
export const FULL_STORY_ID = checkVariable(import.meta.env.VITE_FULL_STORY_ID)
export const SENTRY_KEY = checkVariable(import.meta.env.VITE_SENTRY_KEY)
export const SLACK_ID = checkVariable(import.meta.env.VITE_SLACK_ID)
export const ONE_SCHEMA_CLIENT_ID = checkVariable(
  import.meta.env.VITE_ONE_SCHEMA_CLIENT_ID
)
export const UPLOADCARE_KEY = checkVariable(import.meta.env.VITE_UPLOADCARE_KEY)
export const SENTRY_ORG = 'parafin-a3'
export const SEGMENT_KEY = import.meta.env.VITE_SEGMENT_KEY // we don't have a key for dev
export const UPLOADCARE_CDN = 'https://uploads.parafin.com'
export const UPLOADCARE_SECURE_KEY = checkVariable(
  import.meta.env.VITE_UPLOADCARE_SECURE_KEY
)
export const UPLOADCARE_SECURE_CDN = 'https://secure-uploads.parafin.com'
export const PARAGON_PROJECT_ID = checkVariable(
  import.meta.env.VITE_PARAGON_PROJECT_ID
)
export const CUBE_ANALYTICS_URL = checkVariable(
  import.meta.env.VITE_CUBE_ANALYTICS_API
)
export const STATSIG_KEY = checkVariable(
  import.meta.env.VITE_PUBLIC_STATSIG_KEY
)
export const METICULOUS_PROJECT_ID = checkVariable(
  import.meta.env.VITE_PUBLIC_METICULOUS_PROJECT_ID
)
